import React, { createContext, useState, useContext } from 'react';

const CameraContext = createContext();

export const CameraProvider = ({ children }) => {
  const [cameraStream, setCameraStream] = useState(null);

  return (
    <CameraContext.Provider value={{ cameraStream, setCameraStream }}>
      {children}
    </CameraContext.Provider>
  );
};

export const useCamera = () => useContext(CameraContext);
