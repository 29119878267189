import React, { createContext, useEffect, useState } from "react";
import Header from "../components/header/Header";
import UserSideBar from "../components/user-sideBar/UserSidebar";
import MobileMenu from "../components/user-sideBar/MobileMenu";
import { useLocation } from "react-router-dom";
import "./layout.css";
export const MenuContext = createContext();

const Layout = ({ page }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  const location = useLocation();
  const isVideoDetailsPage = location.pathname.includes("video-details");

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = screenSize <= 900;
  const isMedium = screenSize > 900 && screenSize <= 1160;
  const isLarge = screenSize > 1160;

  return (
    <div className="layout">
      <MenuContext.Provider
        value={[mobileMenuOpen, setMobileMenuOpen, openSidebar, setOpenSidebar]}
      >
        {isMobile ? <MobileMenu /> : <UserSideBar />}
        <div className="main-content">
          <Header isMediumScreen={isMedium} />
          {page}
        </div>
      </MenuContext.Provider>
    </div>
  );
};

export default Layout;
