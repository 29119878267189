import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { typo } from "../../../style";
import { updatePassword } from "../../../apis/account/profile";
import CustomSnackbar from "../../../components/snackbar/CustomSnackbar";

const ChangePassword = () => {
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [openSnackbar, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters long"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const response = await updatePassword(values);
        handleSnackbarOpen("Password updated successfully!");
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
  };

  return (
    <div className="py-[20px] sm:px-[50px] px-[20px]">
      <h1 className={typo.pages_header + " mt-5"}>Change Password</h1>
      <form onSubmit={formik.handleSubmit}>
        {/* FULL NAME */}
        <div className="flex gap-[20px] sm:gap-[40px]  mt-5 sm:mt-10 flex-wrap sm:flex-nowrap">
          <p className="min-w-[150px] mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Current password
          </p>
          <div className="w-full">
            <input
              name="currentPassword"
              placeholder="Enter your current password"
              className="border w-full border-[#DCDCDC] rounded-[8px] p-4 flex-grow"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentPassword}
            />
            {formik.touched.currentPassword && formik.errors.currentPassword ? (
              <div className="text-red-500">
                {formik.errors.currentPassword}
              </div>
            ) : null}
          </div>
        </div>
        {/* New Password */}
        <div className="flex gap-[20px] sm:gap-[40px] mt-5 sm:mt-10 flex-wrap sm:flex-nowrap">
          <p className="min-w-[150px] mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            New Password
          </p>
          <div className="w-full">
            <input
              name="newPassword"
              placeholder="Enter your new password"
              className="border w-full border-[#DCDCDC] rounded-[8px] p-4 flex-grow"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="text-red-500">{formik.errors.newPassword}</div>
            ) : null}
          </div>
        </div>
        {/* Confirm password */}
        <div className="flex gap-[20px] sm:gap-[40px] mt-5 sm:mt-10 flex-wrap sm:flex-nowrap">
          <p className="min-w-[150px] mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Confirm password
          </p>
          <div className="w-full">
            <input
              name="confirmPassword"
              placeholder="Confirm password"
              className="border w-full border-[#DCDCDC] rounded-[8px] p-4 flex-grow"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-red-500">
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex justify-items items-end justify-end p-4 space-x-6 mt-8">
          <button
            type="submit"
            className="rounded-[8px] min-w-[92px] bg-[#F6552D] border border-[#DCDCDC] p-2 text-white font-[400] font-Poppins text-[15px] font-bold"
          >
            Save
          </button>
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          closeSnackbar={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </form>
    </div>
  );
};

export default ChangePassword;
