
import React, { useState, useEffect } from "react";
import { avatar, message } from "../../../assets/images";
import axios from "axios";
import Moment from 'moment';
import { useUser } from "../../../state/UserContext";

export default function ProductComments({ product }) {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [showComments, setShowComments] = useState(3);
    const { userData } = useUser();
    const styles = {
        fontSize: "large",
        fontWeight: "bold",
        width: "52px",
        height: "52px",
        borderRadius: "50px",
        color: "white",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fb8365"
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userId = userData._id;
        const productId = product._id;
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/comments/add-comment-to-product/${product?._id}`, {
                userId: userId,
                recent_text: comment,
            });
            setComment('');
            setComments([...comments, res.data]);
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    const fetchComments = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/comments/get-product-comments`, { product: product?._id });
            setComments(response.data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const toggleAll = (e) => {
        e.preventDefault();
        if (showComments > 3) {
            setShowComments(3);
        } else {
            setShowComments(comments.length);
        }
    }

    useEffect(() => {
        fetchComments();
    }, []);

    return (
        <>
            <hr className="text-[#D9E1EC]" />
            <div className="flex justify-between flex-wrap">
                <p className="text-[#21293C] text-[24px] font-[700]">
                    {comments.length} Reviews
                </p>
            </div>
            {comments.slice(0, showComments).map((comment, index) => (
                <div key={`comment-${index}`} className="flex flex-col gap-4">
                    <div className="flex space-x-2">
                        {
                            userData?.picture.length > 0
                                ?
                                <img
                                    alt="profile-picture"
                                    name="picture"
                                    src={userData?.picture.includes('upload') ? `${process.env.REACT_APP_API_URL}/${userData?.picture}` : userData?.picture}
                                    className="w-[52px] h-[52px] rounded-[12px] object-cover"
                                />
                                :
                                <div style={styles}>
                                    {userData?.firstName.slice(0, 1).toUpperCase()}{userData?.lastName.slice(0, 1).toUpperCase()}
                                </div>
                        }
                        <div>
                            <p className="text-[#21293C] font-[600] text-[16px]">{comment.user.firstName} {comment.user.lastName}</p>
                            <p className="text-[#4B587C] font-[500] text-[16px]">{comment.user.role}</p>

                            {/* <Rating /> */}
                        </div>
                        {/* {userData._id!==comment.user._id && <p className="text-[#FF6154] text-[16px] font-[400]">Follow</p>} */}
                    </div>
                    <div className="text-[#4B587C] font-[400] text-[17px]">
                        {comment.recent_text}
                    </div>
                    <div className="text-[#4B587C] font-[400] text-[14px]">
                        {Moment(comment.updated_at).format('MMM d, yyyy hh:mm')}
                    </div>
                </div>
            ))}
            {
                comments.length > 3 && showComments == 3 && <button className="p-2 text-[#21293C] bg-[#FFF] border-[#D9E1EC] font-[600] rounded-[4px] w-full" onClick={(e) => toggleAll(e)}>View All Reviews</button>
            }
            {
                comments.length > 3 && showComments == comments.length && <button className="p-2 text-[#21293C] bg-[#FFF] border-[#D9E1EC] font-[600] rounded-[4px] w-full" onClick={(e) => toggleAll(e)}>Collapse Reviews</button>
            }
            <div className="text-[#3E3232] text-[16px] font-[700]">Comment</div>
            <div className="flex flex-col bg-[#F5F5F5] rounded-[8px] p-8 mt-4">
                <form >
                    <textarea
                        rows="7"
                        placeholder="Add your comment here ..."
                        className="bg-transparent border-none focus:outline-none w-full"
                        style={{ borderRadius: 'none' }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <div className="flex justify-end items-end ">
                        <button className="bg-[#FB6D4A] p-2 rounded-[8px] text-white font-[600] flex space-x-2 " onClick={(e) => handleSubmit(e)}>
                            <img src={message} className="mt-1 w-4 h-4" alt="send comment" />
                            <span>Send comment</span>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}