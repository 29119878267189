import React, { useEffect, useState } from "react";
import style from "./videoDetails.module.scss";
import { back_button, crossButton } from "../../../assets/images/index";
import Comment from "../../../components/comments/Comment";
import axios from "axios";
import { useUser } from "../../../state/UserContext";
import { useFetcher, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { robo } from "../../../assets/images";
function VideoDetails() {
  const videoId = useParams().id;
  const navigate = useNavigate();
  const [isFollowed, setIsFollowed] = useState(false);
  const [video, setVideo] = useState(null);
  const [views, setViews] = useState(0);
  const [productFollowers, setProductFollowers] = useState([]);
  const [businessOwnerVideos, setBusinessOwnerVideos] = useState([]);
  const { userData } = useUser();
  const isBusinessOwner = JSON.parse(
    window.localStorage.getItem("isBusinessOwner")
  );

  const getBusinessOwnerVideos = async () => {
    if (isBusinessOwner) {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/video-by-business-owner/${userData._id}`
      );
      const response = await res.json();
      setBusinessOwnerVideos(response.map((video) => video.videoId));
    }
  };

  const formatDate = (inputdate) => {
    const date = new Date(inputdate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  const getVideoInformation = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/video-by-id/${videoId}`
      );
      const response = await res.json();
      if (response) {
        setVideo(response);
        return response;
      }
    } catch (error) {}
  };

  useEffect(() => {
    getVideoInformation();
  }, []);

  useEffect(() => {}, [video]);

  const getProductInformation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${video?.productId}`
      );
      if (response) return response.data;
    } catch (error) {
      return null;
    }
  };

  const followProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/add-follow`,
        { userId: userData._id, productId: video?.productId }
      );
    } catch (error) {}
  };

  const removeFollowProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/remove-follow`,
        { userId: userData._id, productId: video?.productId }
      );
    } catch (error) {}
  };

  const handleFollow = async () => {
    if (productFollowers.includes(userData._id)) {
      setIsFollowed(false);
      await removeFollowProduct();
      setProductFollowers(
        productFollowers.filter((user) => user !== userData._id)
      );
    } else {
      setIsFollowed(true);
      await followProduct();
      setProductFollowers([...productFollowers, userData._id]);
    }
  };

  const closeHandle = () => {
    const activeItem = localStorage.getItem("activeItem");
    if (activeItem === "My Space") navigate("/my-space");
    else navigate("/");
  };
  return (
    <div className={style["wrapper"]}>
      <div className={style["close-button-container"]}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={closeHandle}
        >
          <img
            src={back_button}
            alt="close"
            className={style["close-button"]}
          />{" "}
          <span style={{ fontWeight: "500", fontSize: "16px" }}>Back</span>
        </div>
      </div>
      <div className={style["video-page-container"]}>
        <div className={style["section-1"]}>
          <div className={style["video-container"]}>
            <video controls src={video?.videoURL}>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className={style["section-2"]}>
          <div className={style["details-container"]}>
            <div className={style["details-video"]}>
              <Link
                key={video?.productId}
                className={style.product}
                to={`/product-details/${video?.product._id}`}
              >
                {video?.product.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${video?.product.image}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = robo;
                    }}
                    alt="product-logo"
                  ></img>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  ></svg>
                )}
              </Link>
              <div className={style["product-information"]}>
                <Link
                  key={video?.product._id}
                  className={style.product}
                  to={`/product-details/${video?.product._id}`}
                >
                  <span className={style["product-name"]}>
                    {video?.product.name}
                  </span>{" "}
                </Link>
                <p>
                  <span style={{ color: " #F6552DB5" }}>{video?.category}</span>{" "}
                  {views} views. {formatDate(video?.created_at)}
                </p>
              </div>
            </div>
            {!businessOwnerVideos.includes(videoId) && (
              <button className={style["follow-button"]} onClick={handleFollow}>
                {isFollowed
                  ? "Followed"
                  : productFollowers.includes(userData._id)
                  ? "Followed"
                  : "Follow"}
              </button>
            )}
          </div>
          <span style={{ textDecoration: "underline" }}>Comments</span>
          <Comment videoId={videoId} productId={video?.product._id}></Comment>
        </div>
      </div>
    </div>
  );
}

export default VideoDetails;
