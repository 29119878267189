import React, { useState, useContext, useEffect } from "react";
import style from "./userSidebar.module.scss";
import {
  productIcon,
  productIconActive,
  homeIcon,
  homeIconActive,
  videoListIcon,
  videoListIconActive,
  uploadIcon,
  recordIcon,
  close,
  final_logo,
  olindiasLogo,
  calendar_icon,
  history_icon,
  calendar_icon_active,
  history_icon_active,
  robo
} from "../../assets/images/index";
import { RiVideoAddFill } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { MenuContext } from "../../pages/Layout";
import { Context } from "../../state/UserContext";
import { AiOutlinePlus } from "react-icons/ai";
import ProductsPopUp from "../products-popups/ProductsPopUp";
import ModalContent from "./RecordModal";
import { Link } from "react-router-dom";
import CreateProductModal from "../create-new-product/CreateProductModal";
const UserSideBar = () => {
  const storedActiveItem = localStorage.getItem("activeItem");
  const userData = JSON.parse(window.localStorage.getItem("user"));
  const [activeItem, setActiveItem] = useState(storedActiveItem || "Explore");
  const [openSidebar, setOpenSidebar, mobileScreen] = useContext(MenuContext);
  const { openProductPopUp, setOpenProductPopUp, newProduct } =
    useContext(Context);
  const { openChooseProduct, setOpenChooseProduct, newVideoProduct } =
    useContext(Context);

  const [productList, setProductList] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const isBusinessOwner = JSON.parse(
    window.localStorage.getItem("isBusinessOwner")
  );

  const getBusinessOwenerProducts = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products/business-owner-products/${userData._id}`
      );
      const response = await res.json();
      setProductList(response);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    if (userData != null) {
      getBusinessOwenerProducts();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeItem", activeItem);
  }, [activeItem]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [showCreateOption, setShowCreateOption] = useState(false);
  const createVideoItems = [
    {
      title: "Record Video",
      icon: recordIcon,
      link: "/record",
    },
    {
      title: "Upload Video",
      icon: uploadIcon,
      link: "/upload",
    },
  ];
  const otherSidebarItems = [
    {
      title: "Explore",
      icon: [homeIcon, homeIconActive],
      link: "/",
    },

    {
      title: "Products Store",
      icon: [productIcon, productIconActive],
      link: "/products-store",
    },
    {
      title: "My Space",
      icon: [videoListIcon, videoListIconActive],
      link: "/my-space",
    },
    // {
    //   title: "Calendar",
    //   icon: [calendar_icon, calendar_icon_active],
    //   link: "/my-space",
    // },
  ];

  const handleCreateVideoButton = () => {
    setShowCreateOption(!showCreateOption);
  };

  const closeSidebar = () => {
    setOpenSidebar(false);
  };

  const handleNavigation = (item) => {
    setActiveItem(item.title);
    navigate(`${item.link}`);
    localStorage.setItem("activeItem", activeItem);
  };
  const handleCreateVideo = () => {
    setOpenProductPopUp(true);
  };

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <aside
      className={
        openSidebar ? style["user-sidebar-phone"] : style["user-sidebar"]
      }
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className={style["sidebar-container"]}>
        <div className={style["top-section"]}>
          <img
            src={final_logo}
            alt="logo"
            className={style["logo"]}
            onClick={handleLogoClick}
          />
          {openSidebar && (
            <div className={style["bars"]}>
              {" "}
              <img src={close} alt="close" onClick={closeSidebar} />
            </div>
          )}
        </div>
        <div className={style["navigation-container"]}>
          {!mobileScreen && <ModalContent />}
          <div className={style["rest-items"]}>
            {otherSidebarItems
              .slice(0, !userData ? 2 : otherSidebarItems.length + 1)
              .map((item, index) => {
                const isActive = activeItem === item.title;
                return (
                  <div
                    key={index}
                    style={{
                      color: isActive ? "#FB6D4A" : "#000000",
                    }}
                    className={style["nav-link"]}
                    onClick={() => {
                      handleNavigation(item);
                    }}
                  >
                    <div className={style["icon"]}>
                      <img
                        src={item.icon[isActive ? 1 : 0]}
                        alt={item.title}
                      ></img>
                    </div>
                    <span className={style["span-title"]}>{item.title}</span>
                  </div>
                );
              })}
          </div>
        </div>
        {userData && (
          <div className={style["list-product-section"]}>
            <div
              style={{
                width: "80%",
                height: "1px",
                backgroundColor: "#FBE3D9",
                marginBottom: "20px",
                marginLeft: "30px",
              }}
            ></div>
            <span>My Products</span>

            <div className={style["list-container"]}>
              <div className={style["add-product"]} onClick={handleOpenModal}>
                <AiOutlinePlus style={{ color: "#E86747" }} />
                <span>Add Product</span>
              </div>
              <CreateProductModal open={modalOpen} onClose={handleCloseModal} />

              <div
                className={`${style["list-product"]} ${
                  productList.length > 5 ? style["scrollable"] : ""
                }`}
              >
                {productList && productList.length > 0 ? (
                  <>
                    {productList
                      .slice(0, showMore ? productList.length : 3)
                      .map((product, index) => (
                        <Link
                          to={`/product-details/${product._id}`}
                          key={index}
                          className={style["product-item"]}
                        >
                          {product.image ? (
                            <img
                              src={
                                product.image.includes("upload")
                                  ? `${process.env.REACT_APP_API_URL}/${product.image}`
                                  : product.image
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = robo;
                              }}
                              alt="product"
                            />
                          ) : (
                            <img src={robo} alt="default" />
                          )}
                          <span className={style["product-name"]}>
                            {product.name}
                          </span>
                        </Link>
                      ))}
                    {/* {productList.length > 5 && (
                      <div
                        className={style["show-more-product"]}
                        onClick={() => setShowMore(!showMore)}
                      >
                        <img src={showMore ? arrowUp : arrowDown3} alt="arrow" />
                        <span>
                          {showMore
                            ? "Show Less"
                            : `Show ${productList.length - 5} more`}
                        </span>
                      </div>
                    )} */}
                  </>
                ) : (
                  <div></div>
                )}
              </div>
              {/* <Link className={style["add-product"]} to={"/my-space"}>
                <span className="ml-2">Show All List</span>
              </Link> */}
            </div>
          </div>
        )}
      </div>
      <div className={style["bottom-section"]}>
        <a
          href="https://www.olindias.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={style["company-link"]}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ color: "#808080", fontSize: 15, paddingRight: 10 }}>
            powered By
          </span>
          <img
            src={olindiasLogo}
            alt="Company Logo"
            className={style["company-logo"]}
          />
        </a>
        <a
          href="/privacy-policy"
          style={{
            color: "#808080",
            fontSize: 13,
            paddingRight: 10,
            marginTop: 10,
            textDecoration: "underline",
          }}
        >
          Privacy & Policy
        </a>
      </div>
    </aside>
  );
};

export default UserSideBar;
