export const updateProfile = async (values) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    country,
    picture,
    timeZone,
    currency,
  } = values;
  try {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phoneNumber", phoneNumber);
    formData.append("country", country);
    formData.append("picture", picture);
    formData.append("timeZone", timeZone);
    formData.append("currency", currency);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/update-profile/${user._id}`,
      {
        method: "PUT",
        body: formData,
      }
    );
    const res = await response.json();

    return res;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const updatePassword = async (values) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { currentPassword, newPassword, confirmPassword } = values;

  try {
    const formData = new FormData();
    formData.append("currentPassword", currentPassword);
    formData.append("newPassword", newPassword);
    formData.append("confirmPassword", confirmPassword);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/update-password/${user._id}`,
      {
        method: "PUT",
        body: formData,
      
      }
    );
    const res = await response.json();

    return res;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const updateBusinessOwner = async (values) => {
  const businessOwner = JSON.parse(localStorage.getItem("businessOwner"));

  try {

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/business-owner/update/${businessOwner._id}`,
      {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const res = await response.json();

    return res;
  } catch (e) {
    console.log(e);
    return;
  }
};