import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./mySpace.module.scss";
import {
  robo,
  add_product,
  crud_icon,
  playIcon,
  edit_icon,
  delete_icon,
  noProductsIcon,
  NoData,
} from "../../../assets/images";
import VideoDetails from "../../../components/video-details/VideoDetails";
import axios from "axios";
import { Context } from "../../../state/UserContext";
import CreateProductModal from "../../../components/create-new-product/CreateProductModal";
import UpdateProductModal from "../../../components/update-product/UpdateProductModal";
import DeleteProductModal from "../../../components/delete-product/DeleteProductModal";
const MySpace = () => {
  const [productCover, setProductCover] = useState(null);
  const [productCoverPath, setProductCoverPath] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [productImagePath, setProductImagePath] = useState("");
  const { userProductList, setUserProductList } = useContext(Context);
  const [videos, setVideos] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [clicked, setClicked] = useState(false);
  const firstFourProducts = userProductList.slice(0, 4);
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpenUpdateModal = (productId) => {
    const product = userProductList.find((p) => p._id === productId);
    setSelectedProduct(product);
    setUpdateModalOpen(true);
  };

  const handleOpenDeleteModal = (productId) => {
    setSelectedProductId(productId);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleCloseUpdateModal = () => setUpdateModalOpen(false);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleDeleteProduct = (productId) => {
    setUserProductList((prevProducts) =>
      prevProducts.filter((product) => product._id !== productId)
    );
    setFilteredProducts((prevFiltered) =>
      prevFiltered.filter((product) => product._id !== productId)
    );
  };
  const [filteredProducts, setFilteredProducts] = React.useState(
    Array.isArray(userProductList) ? userProductList : []
  );
  useEffect(() => {
    setFilteredProducts(userProductList);
  }, [userProductList]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = userProductList.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(userProductList);
    }
  };
  const productsToDisplay =
    filteredProducts.length > 0 ? filteredProducts : userProductList;

  const toggleDropdown = (viedoId) => {
    setVisibleDropdown((prev) => (prev === viedoId ? null : viedoId));
  };

  const [currentSection, setCurrentSection] = useState(true);

  const handleSeeAllClick = () => {
    setCurrentSection(!currentSection);
    setSearchQuery("");
    setFilteredProducts(userProductList);
  };
  const handleCrudActionProduct = (action, productId) => {
    if (action === "delete") {
      handleOpenDeleteModal(productId);
    } else if (action === "edit") {
      handleOpenUpdateModal(productId);
    }
  };
  const handleCrudActionVideo = async (action, videoId) => {
    if (action === "delete") {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/videos/${videoId}`
        );
        setVideos((prevVideos) =>
          prevVideos.filter((video) => video._id !== videoId)
        );
        setClicked(false);
      } catch (error) {
        console.error("Error deleting video:", error);
      }
    } else if (action === "edit") {
    } else if (action === "publish") {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/videos/publish/${videoId}`
        );
        if (res.data.success) {
          setVideos((prevVideos) =>
            prevVideos.map((video) =>
              video._id === videoId ? { ...video, status: "published" } : video
            )
          );
        }
      } catch (error) {
        console.error("Error publishing video:", error);
      }
    }
  };

  const handleAction = (action, id, type) => {
    setVisibleDropdown(null);
    if (type === "product") {
      handleCrudActionProduct(action, id);
    } else if (type === "video") {
      handleCrudActionVideo(action, id);
    }
  };

  const userData = JSON.parse(window.localStorage.getItem("user"));
  const navigate = useNavigate();
  const handleCardClick = (index, videoId) => {
    setClicked(true);
    setClickedIndex(index);
    navigate(`/video-details/${videoId}`);
  };

  const handleCoverChange = async (event) => {
    setProductCoverPath(event.target.files[0]);
    setProductCover(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    updateProfileCover();
  }, [productCoverPath]);

  useEffect(() => {
    updateProfilePicture();
  }, [productImagePath, productImage]);

  const handleImageChange = (event) => {
    setProductImagePath(event.target.files[0]);
    setProductImage(URL.createObjectURL(event.target.files[0]));
  };

  // const getBusinessOwnerVideos = async () => {
  //   try {
  //     const res = await fetch(
  //       `${process.env.REACT_APP_API_URL}/videos/video-by-business-owner/${userData._id}`
  //     );
  //     const response = await res.json();
  //     setVideos(response);
  //   } catch (error) {
  //     console.error("Error fetching posts:", error);
  //   }
  // };

  // const getBusinessOwenerProducts = async () => {
  //   try {
  //     const res = await fetch(
  //       `${process.env.REACT_APP_API_URL}/products/business-owner-products/${userData._id}`
  //     );
  //     const response = await res.json();
  //     setUserProductList(response);
  //   } catch (error) {
  //     console.error("Error fetching posts:", error);
  //   }
  // };
  const updateProfileCover = async () => {
    const formData = new FormData();
    formData.append("email", userData.email);
    formData.append("cover", productCoverPath);
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/users/update-cover/${userData._id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res) {
      const response = res.data;
    }
  };
  const updateProfilePicture = async () => {
    const form = new FormData();
    form.append("email", userData.email);
    form.append("image", productImagePath);
    if (form.get("image") !== "") {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update-profile-picture/${userData._id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        const response = res.data;
      }
    }
  };
  // useEffect(() => {
  //   if (userData != null) {
  //     getBusinessOwenerProducts();
  //     getBusinessOwnerVideos();
  //     fetchUser();
  //   }
  // }, [videos]);

  // const fetchUser = async () => {
  //   const res = await fetch(
  //     `${process.env.REACT_APP_API_URL}/users/${userData._id}`
  //   );
  //   const response = await res.json();
  //   setProductImage(response.picture);
  //   setProductCover(response.cover);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = JSON.parse(window.localStorage.getItem("user"));
        if (!userData) return;
        const [productsRes, videosRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}/products/business-owner-products/${userData._id}`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/videos/video-by-business-owner/${userData._id}`
          ),
        ]);
        setUserProductList(productsRes.data);
        setFilteredProducts(productsRes.data);
        setVideos(videosRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={style.container}>
      {loading ? (
        <div className={style.loading}>Loading...</div>
      ) : (
        <>
          <div className={`${style["container"]}`}>
            <div className={`${style["header"]}`}>My Space</div>
            <div className={`${style["product-info"]}`}>
              <div className={`${style["cover-box"]}`}>
                {productCover && (
                  <img
                    src={
                      productCover.includes("uploads")
                        ? `${process.env.REACT_APP_API_URL}/${productCover}`
                        : productCover
                    }
                    alt="Uploaded"
                  />
                )}
                {!productCover && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  >
                    <path
                      d="M4.5149 33.2675L4.4799 33.3025C4.0074 32.27 3.7099 31.0975 3.5874 29.8025C3.7099 31.08 4.0424 32.235 4.5149 33.2675Z"
                      fill="#FEFFFF"
                    />
                    <path
                      d="M15.75 18.165C18.0502 18.165 19.915 16.3002 19.915 14C19.915 11.6997 18.0502 9.83496 15.75 9.83496C13.4497 9.83496 11.585 11.6997 11.585 14C11.585 16.3002 13.4497 18.165 15.75 18.165Z"
                      fill="#FEFFFF"
                    />
                    <path
                      d="M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.3325C3.5 30.24 3.8325 31.9025 4.48 33.3025C5.985 36.6275 9.205 38.5 13.6675 38.5H28.3325C34.7025 38.5 38.5 34.7025 38.5 28.3325V24.325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM35.6475 21.875C34.2825 20.7025 32.0775 20.7025 30.7125 21.875L23.4325 28.1225C22.0675 29.295 19.8625 29.295 18.4975 28.1225L17.9025 27.6325C16.66 26.5475 14.6825 26.4425 13.2825 27.3875L6.7375 31.78C6.3525 30.8 6.125 29.6625 6.125 28.3325V13.6675C6.125 8.7325 8.7325 6.125 13.6675 6.125H28.3325C33.2675 6.125 35.875 8.7325 35.875 13.6675V22.0675L35.6475 21.875Z"
                      fill="#FEFFFF"
                    />
                  </svg>
                )}
                <input
                  type="file"
                  className={`${style["input-img"]}`}
                  id="coverInput"
                  onChange={handleCoverChange}
                />
                <label htmlFor="coverInput" className={`${style["label-img"]}`}>
                  <span>Edit Cover</span>
                </label>
              </div>
              <div className={`${style["img-box"]}`}>
                {userData?.picture ? (
                  <img
                    src={
                      userData?.picture.includes("uploads")
                        ? `${process.env.REACT_APP_API_URL}/${userData?.picture}`
                        : productImage
                    }
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  >
                    <path
                      d="M4.5149 33.2675L4.4799 33.3025C4.0074 32.27 3.7099 31.0975 3.5874 29.8025C3.7099 31.08 4.0424 32.235 4.5149 33.2675Z"
                      fill="#FEFFFF"
                    />
                    <path
                      d="M15.75 18.165C18.0502 18.165 19.915 16.3002 19.915 14C19.915 11.6997 18.0502 9.83496 15.75 9.83496C13.4497 9.83496 11.585 11.6997 11.585 14C11.585 16.3002 13.4497 18.165 15.75 18.165Z"
                      fill="#FEFFFF"
                    />
                    <path
                      d="M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.3325C3.5 30.24 3.8325 31.9025 4.48 33.3025C5.985 36.6275 9.205 38.5 13.6675 38.5H28.3325C34.7025 38.5 38.5 34.7025 38.5 28.3325V24.325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM35.6475 21.875C34.2825 20.7025 32.0775 20.7025 30.7125 21.875L23.4325 28.1225C22.0675 29.295 19.8625 29.295 18.4975 28.1225L17.9025 27.6325C16.66 26.5475 14.6825 26.4425 13.2825 27.3875L6.7375 31.78C6.3525 30.8 6.125 29.6625 6.125 28.3325V13.6675C6.125 8.7325 8.7325 6.125 13.6675 6.125H28.3325C33.2675 6.125 35.875 8.7325 35.875 13.6675V22.0675L35.6475 21.875Z"
                      fill="#FEFFFF"
                    />
                  </svg>
                )}
                <input
                  type="file"
                  className={`${style["input-img"]}`}
                  id="imageInput"
                  onChange={handleImageChange}
                />
                <label htmlFor="imageInput" className={`${style["label-img"]}`}>
                  <span>Edit Image</span>
                </label>
              </div>
            </div>
            <CreateProductModal open={modalOpen} onClose={handleCloseModal} />

            {currentSection && (
              <>
                <div className={`${style["product-list"]}`}>
                  <h1 className={`${style["title"]}`}>My product List</h1>
                  <span
                    className={`${style["view-more"]}`}
                    onClick={handleSeeAllClick}
                  >
                    See All
                  </span>
                </div>
                <div className={`${style["products"]}`}>
                  <Link onClick={handleOpenModal} className={style["add-new"]}>
                    <div className={style["new-img-wrapper"]}>
                      <div className={style["new-img"]}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                        >
                          <path
                            d="M9.85714 13.1429H0V9.85714H9.85714V0H13.1429V9.85714H23V13.1429H13.1429V23H9.85714V13.1429Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                    <span className={style["product-name"]}>Add New</span>
                  </Link>
                  {userProductList.length > 0 &&
                    userProductList.map((product) => (
                      <Link
                        key={product._id}
                        className={style.product}
                        to={`/product-details/${product?._id}`}
                      >
                        <div className={style["product-img-wrapper"]}>
                          {product?.image ? (
                            <img
                              src={
                                product.image.includes("upload")
                                  ? `${process.env.REACT_APP_API_URL}/${product.image}`
                                  : product.image
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = robo;
                              }}
                              alt="product"
                            />
                          ) : (
                            <img src={robo} alt="default" />
                          )}
                        </div>
                        <span className={style["product-name"]}>
                          {product?.name}
                        </span>
                      </Link>
                    ))}
                </div>
                {/* )} */}
                {videos.length > 0 && (
                  <div className={`${style["records"]}`}>
                    {videos.map((video, index) => {
                      return (
                        <div key={index}>
                          <div className={`${style["record"]}`}>
                            <div className={style["video-container"]}>
                              <img
                                src={video.capture}
                                style={{
                                  borderRadius: 30,
                                  width: "100%",
                                  height: 260,
                                }}
                                onClick={() =>
                                  handleCardClick(index, video._id)
                                }
                              />
                              <video
                                className={style["video-element"]}
                                src={video.videoURL}
                                onLoadedMetadata={(e) => {
                                  const duration = e.target.duration;
                                  const minutes = Math.floor(duration / 60);
                                  const seconds = Math.floor(duration % 60);
                                  const formattedDuration = `${minutes}:${
                                    seconds < 10 ? "0" : ""
                                  }${seconds}`;
                                  document.getElementById(
                                    `duration-${index}`
                                  ).innerText = formattedDuration;
                                }}
                                style={{ display: "none" }}
                              />
                              <div
                                id={`duration-${index}`}
                                className={style["video-duration"]}
                              ></div>
                              <img
                                src={playIcon}
                                alt="Play Icon"
                                className={style["play-icon"]}
                                onClick={() =>
                                  handleCardClick(index, video._id)
                                }
                              />
                            </div>
                          </div>
                          {clicked && clickedIndex === index && (
                            <div className={style["video-details-overlay"]}>
                              <div className={style["video-details-container"]}>
                                <VideoDetails
                                  className={style["video-details"]}
                                  videoId={video._id}
                                  productName={video.product.name}
                                  videoPreview={video.videoURL}
                                  productImage={video.product.image}
                                  textPreview={video.textPreview}
                                  productId={video.productId}
                                  index={clickedIndex}
                                  setClicked={setClicked}
                                />
                              </div>
                            </div>
                          )}
                          <div className={style["video-info"]}>
                            <div className={style["video-product-details"]}>
                              <div className={style["prod-name"]}>
                                {video.product.name}
                              </div>
                              <button
                                onClick={() => toggleDropdown(video._id)}
                                className={style["dropdown-button"]}
                              >
                                <img src={crud_icon} alt="CRUD Icon" />
                              </button>
                              {visibleDropdown === video._id && (
                                <div className={style["crud-dropdown"]}>
                                  {/* <div className={style["crud-actions"]} onClick={() => handleAction('edit', video._id)}>
                              <img src={edit_icon} />
                              <span>Edit</span>
                            </div> */}
                                  <div
                                    className={style["crud-actions"]}
                                    onClick={() =>
                                      handleAction(
                                        "publish",
                                        video._id,
                                        "video"
                                      )
                                    }
                                  >
                                    <img src={edit_icon} />
                                    <span>Publish</span>
                                  </div>
                                  <div
                                    className={style["crud-actions"]}
                                    onClick={() =>
                                      handleAction("delete", video._id, "video")
                                    }
                                  >
                                    <img src={delete_icon} />
                                    <span>Delete</span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={style["more-details"]}>
                              <div className={style["video-owner"]}>
                                {video.product.business_owner.firstName +
                                  " " +
                                  video.product.business_owner.lastName}
                              </div>
                              <div className={style["video-type"]}>
                                {video.category}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {!currentSection && (
              <>
                <div className={style["product-section"]}>
                  <div className={style["back-section"]}>
                    <div onClick={handleSeeAllClick}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="25"
                        viewBox="0 0 12 25"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.34299 12.5L10.414 19.571L8.99999 20.985L1.22199 13.207C1.03451 13.0195 0.929199 12.7652 0.929199 12.5C0.929199 12.2348 1.03451 11.9805 1.22199 11.793L8.99999 4.015L10.414 5.429L3.34299 12.5Z"
                          fill="#111111"
                        />
                      </svg>
                    </div>
                    <h1>List Products</h1>
                  </div>
                  <div className={style["product-search"]}>
                    <div className={style["search-box"]}>
                      <input
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      ></input>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                          stroke="#ADA7A7"
                          strokeWidth="1.24266"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22 22L20 20"
                          stroke="#ADA7A7"
                          strokeWidth="1.24266"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className={style["add-product"]}>
                      <button onClick={handleOpenModal}>Add Product</button>
                      <CreateProductModal
                        open={modalOpen}
                        onClose={handleCloseModal}
                      />
                    </div>
                  </div>
                </div>
                {userProductList.length !== 0 &&
                filteredProducts?.length === 0 ? (
                  <div className={style["no-result-box"]}>
                    <img src={NoData} alt="no products" />
                    <span>No Product Found</span>
                  </div>
                ) : (
                  <div className={style["user-products-list"]}>
                    {productsToDisplay.length > 0 &&
                      productsToDisplay.map((product) => (
                        <div
                          key={product._id}
                          className={style["product-card"]}
                        >
                          <div className={style["crud"]}>
                            <button
                              onClick={() => toggleDropdown(product._id)}
                              className={style["dropdown-button"]}
                            >
                              <img src={crud_icon} alt="CRUD Icon" />
                            </button>
                            {visibleDropdown === product._id && (
                              <div className={style["crud-dropdown"]}>
                                <div
                                  className={style["crud-actions"]}
                                  onClick={() =>
                                    handleAction("edit", product._id, "product")
                                  }
                                >
                                  <img src={edit_icon} alt="Edit" />
                                  <span>Edit</span>
                                </div>
                                <div
                                  className={style["crud-actions"]}
                                  onClick={() =>
                                    handleAction(
                                      "delete",
                                      product._id,
                                      "product"
                                    )
                                  }
                                >
                                  <img src={delete_icon} alt="Delete" />
                                  <span>Delete</span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={style["details"]}>
                            <Link
                              key={product._id}
                              className={style.product}
                              to={`/product-details/${product?._id}`}
                            >
                              <div className={style["product-img-wrapper"]}>
                                {product?.image ? (
                                  <img
                                    src={
                                      product.image.includes("upload")
                                        ? `${process.env.REACT_APP_API_URL}/${product.image}`
                                        : product.image
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = robo;
                                    }}
                                    alt="product"
                                  />
                                ) : (
                                  <img src={robo} alt="default" />
                                )}
                              </div>
                              <span className={style["product-name"]}>
                                {product?.name}
                              </span>
                            </Link>
                            <DeleteProductModal
                              open={deleteModalOpen}
                              onClose={handleCloseDeleteModal}
                              productId={selectedProductId}
                              onDelete={handleDeleteProduct}
                            />
                          </div>
                          <UpdateProductModal
                            open={updateModalOpen}
                            onClose={handleCloseUpdateModal}
                            productId={selectedProduct}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      ;
    </div>
  );
};

export default MySpace;
