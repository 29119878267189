import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateProfile } from "../../../apis/account/profile";
import { useUser } from "../../../state/UserContext";
import "./profile.css";
import CustomSnackbar from "../../../components/snackbar/CustomSnackbar";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  //phoneNumber: Yup.string().required("Phone Number is required"),
  country: Yup.string().required("Country is required"),
  language: Yup.string().required("Language is required"),
  timeZone: Yup.string().required("Time Zone is required"),
  currency: Yup.string().required("Currency is required"),
});

const EditProfile = () => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(window.localStorage.getItem("user"))
  );
  const [phone, setPhone] = useState(currentUser?.phoneNumber);
  const [imageUrl, setImageUrl] = useState("");
  const { setUser, userData } = useUser();
  const [imageError, setImageError] = useState(false);
  const [openSnackbar, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handlePhoneChange = (value) => {
    setPhone(value);
    formik.setFieldValue("phoneNumber", value);
  };
  const handleImageError = () => {
    setImageError(true);
  };
  useEffect(() => {
    setCurrentUser(userData);
    if (userData?.picture.length > 0) {
      setImageUrl(
        userData?.picture.includes("upload")
          ? `${process.env.REACT_APP_API_URL}/${userData?.picture}`
          : userData?.picture
      );
    }
  }, [userData]);

  const initialValues = {
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    email: currentUser?.email || "",
    phoneNumber: currentUser?.phoneNumber || "",
    country: currentUser?.country || "",
    language: currentUser?.language || "English",
    timeZone: currentUser?.timeZone || "USA",
    currency: currentUser?.currency || "Dollar",
    picture: currentUser?.picture,
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        values.picture =
          values.picture !== null
            ? values.picture !== currentUser?.picture
              ? values.picture
              : currentUser?.picture
            : "";
        const response = await updateProfile(values);
        window.localStorage.setItem("user", JSON.stringify(response.user));
        handleSnackbarOpen("Profile updated successfully!");
        setUser(response.user);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <section className="py-[20px] sm:px-[50px] px-[20px]">
      <h1 className=" my-5 text-[#202020] font-Poppins font-[700] text-[28px]">
        Edit Profile
      </h1>

      <form
        className="flex flex-col gap-[30px] sm:gap-[40px]"
        onSubmit={formik.handleSubmit}
      >
        {/* AVATAR */}
        <div className="flex gap-[20px] sm:gap-[40px] items-center flex-wrap">
          <p className="min-w-[120px] mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Avatar
          </p>
          <div className="flex gap-[20px] items-center flex-wrap">
            {imageUrl.length > 0 && !imageError ? (
              <img
                alt="profile"
                name="picture"
                src={imageUrl}
                onError={handleImageError}
                className="min-w-[67px] h-[67px] rounded-full object-cover mb-2 "
              />
            ) : (
              <div className="profile-avatar">
                {currentUser?.firstName.slice(0, 1).toUpperCase()}
                {currentUser?.lastName.slice(0, 1).toUpperCase()}
              </div>
            )}

            <div className="flex gap-[16px] sm:flex-nowrap flex-wrap">
              <input
                type="file"
                accept="image/*"
                id="avatar"
                style={{ display: "none" }}
                onChange={(event) => {
                  formik.setFieldValue("picture", event.target.files[0]);
                  setImageUrl(URL.createObjectURL(event.target.files[0]));
                }}
              />
              <label
                htmlFor="avatar"
                className="rounded-[8px] min-w-[92px] bg-[#F6552D] border border-[#DCDCDC] p-2 text-white font-[400] font-Poppins text-[15px] font-bold"
              >
                Change avatar
              </label>
              <button
                type="button"
                className="rounded-[8px] min-w-[92px] bg-white border border-[#F6552D] p-2 text-[#F6552D] font-[400] font-Poppins text-[15px] font-bold"
                onClick={() => {
                  formik.setFieldValue("picture", null);
                  setImageUrl("");
                }}
              >
                Remove avatar
              </button>
            </div>
          </div>
        </div>

        {/* First NAME */}
        <div className="flex gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            First Name
          </p>
          <div className="w-full">
            <input
              name="firstName"
              placeholder="Enter your full name"
              className="border w-full border-[#DCDCDC] rounded-[8px] p-4 flex-grow"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-red-500">{formik.errors.firstName}</div>
            ) : null}
          </div>
        </div>
        {/* LAST NAME */}
        <div className="flex gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Last Name
          </p>
          <div className="w-full">
            <input
              name="lastName"
              placeholder="Enter your full name"
              className="border w-full border-[#DCDCDC] rounded-[8px] p-4 flex-grow"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-red-500">{formik.errors.lastName}</div>
            ) : null}
          </div>
        </div>
        {/* EMAIL */}
        <div className="flex gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Email
          </p>
          <div className="w-full">
            <input
              readOnly={true}
              name="email"
              placeholder="Enter your email"
              className="border w-full border-[#DCDCDC] rounded-[8px] p-4 flex-grow"
              // onChange={formik.handleChange}
              //onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        {/* PHONE NUMBER */}
        <div className="flex gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Phone Number
          </p>
          <MuiPhoneNumber
            name="phoneNumber"
            className="w-full"
            variant="outlined"
            defaultCountry={"us"}
            onChange={handlePhoneChange}
            onBlur={formik.handleBlur}
            value={phone}
          />
        </div>
        {/* COUNTRY */}
        <div className="flex w-full gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Country
          </p>
          <div className="flex flex-col w-full gap-2">
            <Select
              className="flex-grow"
              sx={{
                borderRadius: "8px",
                borderColor: "#dcdcdc",
              }}
              name="country"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
            >
              <MenuItem value="USA">USA</MenuItem>
              <MenuItem value="Uk">UK</MenuItem>
              <MenuItem value="Canada">Canada</MenuItem>
              <MenuItem value="Tunisia">Tunisia</MenuItem>
            </Select>
            <span>
              {formik.touched.country && formik.errors.country ? (
                <div className="text-red-500">{formik.errors.country}</div>
              ) : null}
            </span>
          </div>
        </div>
        {/* LANGUAGE */}
        {/* <div className="flex w-full gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Language
          </p>
          <div className="flex flex-col w-full gap-1">
            <Select
              sx={{
                borderRadius: "8px",
                borderColor: "#dcdcdc",
              }}
              name="language"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.language}
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="French">French</MenuItem>
              <MenuItem value="Arabic">Arabic</MenuItem>
            </Select>
            <span>
              {formik.touched.language && formik.errors.language ? (
                <div className="text-red-500">{formik.errors.language}</div>
              ) : null}
            </span>
          </div>
        </div> */}
        {/* TIME ZONE */}
        {/* <div className="flex w-full gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Time Zone
          </p>
          <div className="flex flex-col w-full gap-2">
            <Select
              sx={{
                borderRadius: "8px",
                borderColor: "#dcdcdc",
              }}
              name="timeZone"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.timeZone}
            >
              <MenuItem value="USA">USA</MenuItem>
              <MenuItem value="UK">UK</MenuItem>
              <MenuItem value="Canada">Canada</MenuItem>
            </Select>
            <span>
              {formik.touched.timeZone && formik.errors.timeZone ? (
                <div className="text-red-500">{formik.errors.timeZone}</div>
              ) : null}
            </span>
          </div>
        </div> */}
        {/* CURRENCY */}
        {/* <div className="flex w-full gap-[15px] sm:gap-[40px] flex-wrap sm:flex-nowrap">
          <p className="min-w-[120px] mt-0 sm:mt-4 font-Poppins font-[500] text-[16px] text-[#202020]">
            Currency
          </p>
          <div className="flex flex-col gap-2 w-full">
            <Select
              sx={{
                borderRadius: "8px",
                borderColor: "#dcdcdc",
              }}
              name="currency"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currency}
            >
              <MenuItem value="Dollar">Dollar</MenuItem>
              <MenuItem value="Dinar">Dinar</MenuItem>
              <MenuItem value="Euro">Euro</MenuItem>
            </Select>
            <span>
              {formik.touched.currency && formik.errors.currency ? (
                <div className="text-red-500">{formik.errors.currency}</div>
              ) : null}
            </span>
          </div>
        </div> */}

        <div className="flex justify-items items-end justify-end p-4 space-x-6">
          <button
            type="button"
            className="rounded-[8px] min-w-[92px] bg-white border border-[#F6552D] p-2 text-[#F6552D] font-[400] font-Poppins text-[15px] font-bold"
            onClick={formik.handleReset}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-[8px] min-w-[92px] bg-[#F6552D] border border-[#DCDCDC] p-2 text-white font-[400] font-Poppins text-[15px] font-bold"
          >
            Save
          </button>
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          closeSnackbar={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </form>
    </section>
  );
};

export default EditProfile;
