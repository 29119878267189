import { useRef, useEffect } from "react";
import style from "./categoriesSelection.module.scss";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { arrowDown, filterIcon, filter_lines } from "../../assets/images/index";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Divider, MenuItem } from "@mui/material";

const CategoriesSelection = ({
  categoriesChecked,
  setCategoriesChecked,
  filter,
  setFilter,
  setChoosenType,
  videoTypes,
}) => {
  const menuContainer = useRef("");
  const [mobileScreen, setMobileScreen] = useState(false);
  const [mediumScreen, setMediumScreen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [categories, setCategories] = useState([]);
  const handleOpenFilter = (event) => {
    setOpenFilter(event.currentTarget);
  };

  const handleClose = () => {
    setOpenFilter(null);
  };

  const fetchCategories = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products/categories/all-categories`
      );
      const response = await res.json();
      return response;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCategories();
        if (response) setCategories(response);
      } catch (error) {
        console.log("errrrror : ", error);
      }
    };
    fetchData();
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = categoriesChecked.indexOf(value);
    let newChecked = [...categoriesChecked];
    if (currentIndex === -1) {
      newChecked = newChecked.filter((item) => item !== "All");
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length === 0 && filter == "") newChecked.push("All");
    setCategoriesChecked(newChecked);
  };

  const handleScroll = () => {
    if (menuContainer.current) {
      menuContainer.current.scrollLeft += 100;
    }
  };

  const handleChooseCategory = (item) => {
    setFilter("");
    if (categoriesChecked.some((selected) => selected === item)) {
      const updatedCategories = categoriesChecked.filter(
        (selected) => selected !== item
      );
      if (updatedCategories.length === 0) updatedCategories.push("All");
      setCategoriesChecked(updatedCategories);
    } else {
      var updatedCategories = [...categoriesChecked, item];
      updatedCategories = updatedCategories.filter(
        (selected) => selected !== "All"
      );
      setCategoriesChecked(updatedCategories);
    }
  };

  const handleChooseAll = () => {
    setFilter("");
    setCategoriesChecked(["All"]);
  };

  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setMediumScreen(true);
    } else {
      setMediumScreen(false);
    }
    if (window.innerWidth < 500) {
      setMobileScreen(true);
    } else {
      setMobileScreen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenList = (event) => {
    setOpenList(event.currentTarget);
  };

  const handleCloseList = () => {
    setOpenList(null);
  };
  const handleChooseVideoType = (type) => {
    setChoosenType(type);
  };
  return (
    <div className={style["navigation-bar"]}>
      {!mobileScreen ? (
        <div className={style["navigation-wrapper"]}>
          <div className={style["menu-wrapper"]}>
            <div className={style["menu-container"]} ref={menuContainer}>
              <ul className={style["menu"]}>
                <li
                  className={
                    categoriesChecked.some((selected) => selected === "All")
                      ? style["selected"]
                      : style["unselected"]
                  }
                  onClick={() => handleChooseAll()}
                >
                  {" "}
                  All
                </li>
                {categories?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        categoriesChecked.some((selected) => selected === item)
                          ? style.selected
                          : style.unselected
                      }
                      onClick={() => handleChooseCategory(item)}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
            {categories.length > 4 && (
              <button className={style["next-icon"]} onClick={handleScroll}>
                <IoIosArrowForward color="#898989" size={20} />
              </button>
            )}
          </div>
          <div className={style["filter-wrapper-button"]}>
            <button
              className={style["filter-button"]}
              onClick={handleOpenFilter}
            >
              <span>Filter</span>
              <img src={filter_lines} alt="filter" />
            </button>
            <Popover
              open={!!openFilter}
              anchorEl={openFilter}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              slotProps={{
                paper: {
                  sx: {
                    p: 0,
                    mt: 1,
                    ml: 0.75,
                    width: 200,
                  },
                },
              }}
            >
              <MenuItem
                disableRipple
                disableTouchRipple
                sx={{ typography: "body2", color: "gray", py: 1.5 }}
              >
                Type Of Videos
              </MenuItem>

              <Divider sx={{ borderStyle: "dashed", m: 0 }} />

              {videoTypes.map((type, index) => (
                <MenuItem
                  key={index}
                  disableRipple
                  disableTouchRipple
                  sx={{ typography: "body2", color: "black", py: 1.5 }}
                  onClick={() => handleChooseVideoType(type)}
                >
                  {type}
                </MenuItem>
              ))}
            </Popover>
          </div>
        </div>
      ) : (
        <div className={style["phone-view"]}>
          <div
            className={style["phone-categories-view"]}
            onClick={handleOpenList}
          >
            <span>Show:</span>
            {categoriesChecked.length > 1 ? (
              <span>{categoriesChecked[0]}...</span>
            ) : (
              <span>{categoriesChecked[0]}</span>
            )}
            <img src={arrowDown} alt="arrow-down"></img>
          </div>
          <Popover
            open={!!openList}
            anchorEl={openList}
            onClose={handleCloseList}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            slotProps={{
              paper: {
                sx: {
                  p: 0,
                  mt: 1,
                  ml: 0.75,
                  width: 350,
                },
              },
            }}
          >
            <List
              dense
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {categories.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    key={value}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(value)}
                        checked={categoriesChecked.indexOf(value) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemText id={labelId} primary={`${value}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popover>
          <div className={style["filter-wrapper-button"]}>
            <button
              className={style["filter-button"]}
              onClick={handleOpenFilter}
            >
              <img src={filter_lines} alt="filter" />
            </button>
            <Popover
              open={!!openFilter}
              anchorEl={openFilter}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              slotProps={{
                paper: {
                  sx: {
                    p: 0,
                    mt: 1,
                    ml: 0.75,
                    width: 200,
                  },
                },
              }}
            >
              <MenuItem
                disableRipple
                disableTouchRipple
                sx={{ typography: "body2", color: "gray", py: 1.5 }}
              >
                Type Of Videos
              </MenuItem>
              <Divider sx={{ borderStyle: "dashed", m: 0 }} />
              {videoTypes.map((type, index) => (
                <MenuItem
                  key={index}
                  disableRipple
                  disableTouchRipple
                  sx={{ typography: "body2", color: "black", py: 1.5 }}
                  onClick={() => handleChooseVideoType(type)}
                >
                  {type}
                </MenuItem>
              ))}
            </Popover>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesSelection;
