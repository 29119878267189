import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import CategoriesSelection from "../../../components/category-selection/CategoriesSelection.jsx";
import CardGrid from "../../../components/card-grid/CardGrid";
import style from "./userBoard.module.scss";
import { Context } from "../../../state/UserContext.jsx";
import { CircularProgress } from "@mui/material";
import { useUser } from "../../../state/UserContext.jsx";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { NoData } from "../../../assets/images/index.js";

const UserBoard = () => {
  const userData = JSON.parse(window.localStorage.getItem("user"));
  const [categoriesChecked, setCategoriesChecked] = useState(["All"]);
  const [checkedVideos, setCheckedVideos] = useState([]);
  const location = useLocation();
  const cachedData = sessionStorage.getItem("videosData");
  const initialVideos = cachedData ? JSON.parse(cachedData) : [];
  const [allVideos, setAllVideos] = useState(initialVideos);
  const { displayVideos, setDisplayVideos } = useContext(Context);
  const [filter, setFilter] = useState("");
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();
  const [videoTypes, setVideosTypes] = useState([]);
  const [choosenType, setChoosenType] = useState("");
  const { setUser } = useUser();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const videosPerLoad = 12; // Load 12 videos at a time

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log("my data", displayVideos);
    const myParam = searchParams.get("data");
    if (myParam) {
      const { user, token } = JSON.parse(myParam);
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("isBusinessOwner", false);
      localStorage.setItem("activeItem", "Explore");
      navigate("/");
    } else if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  const getAllVideos = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/all-videos`
      );
      const response = await res.json();
      return response;
    } catch (error) {
      return [];
    }
  };

  const fetchData = async () => {
    try {
      const currentTime = new Date().getTime();
      const aiCachedData = sessionStorage.getItem("aiVideosData");
      const cachedData = sessionStorage.getItem("videosData");
      const cachedTime = sessionStorage.getItem("videosDataTime");

      const parsedAiData = aiCachedData ? JSON.parse(aiCachedData) : null;
      const parsedCachedData = cachedData ? JSON.parse(cachedData) : null;

      if (parsedAiData && JSON.parse(aiCachedData).length > 0) {
        const response = await JSON.parse(aiCachedData);
        console.log(response);
        setAllVideos(response);
        let types = response.map((video) => video.category);
        types = [...new Set(types)];
        setVideosTypes(types);
      } else if (
        parsedCachedData &&
        cachedTime &&
        currentTime - cachedTime < 3600 * 1000
      ) {
        setAllVideos(parsedCachedData);
        setDisplayVideos(parsedCachedData.slice(0, videosPerLoad));
        let types = parsedCachedData.map((video) => video.category);
        types = [...new Set(types)];
        setVideosTypes(types);
      } else {
        const response = await getAllVideos();
        if (response) {
          setAllVideos(response);
          setDisplayVideos(response.slice(0, videosPerLoad));
          let types = response.map((video) => video.category);
          types = [...new Set(types)];
          setVideosTypes(types);
          localStorage.setItem("videosData", JSON.stringify(response));
          localStorage.setItem("videosDataTime", currentTime.toString());
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    if (userData != null) {
      getBusinessOwenerProducts();
    }
  }, [sessionStorage.getItem("aiVideosData")]);

  const loadMoreVideos = useCallback(() => {
    console.log("working!!!!");
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    const currentCount = displayVideos.length;
    const nextCount = currentCount + videosPerLoad;

    if (currentCount < allVideos.length) {
      setDisplayVideos((prevVideos) => [
        ...prevVideos,
        ...allVideos.slice(currentCount, nextCount),
      ]);
      setHasMore(nextCount < allVideos.length);
    } else {
      setHasMore(false);
    }

    setIsLoadingMore(false);
  }, [allVideos, displayVideos, hasMore, isLoadingMore]);

  const LoadingIndicator = () => (
    <div className="flex justify-center items-center mt-20">
      <CircularProgress />
    </div>
  );

  const getBusinessOwenerProducts = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products/business-owner-products/${userData._id}`
      );
      const response = await res.json();
      setProductList(response);
    } catch (error) {}
  };

  useEffect(() => {
    if (userData != null) {
      getBusinessOwenerProducts();
    }
  }, []);

  const filterVideoByType = () => {
    const videosByType = checkedVideos.filter(
      (video) => video.category === choosenType
    );
    return videosByType;
  };

  useEffect(() => {
    const videosByType = filterVideoByType();
    setDisplayVideos(videosByType.slice(0, 12));
  }, [choosenType]);

  useEffect(() => {
    if (categoriesChecked.includes("All")) {
      setDisplayVideos(allVideos?.slice(0, 12));
    } else {
      setDisplayVideos(checkedVideos.slice(0, 12));
    }
  }, [categoriesChecked, allVideos, checkedVideos]);

  const lastVideoElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        console.log("Observer entries:", entries);
        if (entries[0].isIntersecting && hasMore) {
          console.log("Loading more videos...");
          loadMoreVideos();
        }
      });

      if (node) {
        observer.current.observe(node);
        console.log("Observer connected to:", node);
      }
    },
    [isLoadingMore, hasMore, loadMoreVideos]
  );

  useEffect(() => {
    if (categoriesChecked.includes("All")) {
      setCheckedVideos(allVideos);
    } else {
      const filteredVideos = allVideos.filter((item) => {
        return item.product.categories.some((element) =>
          categoriesChecked.includes(element)
        );
      });
      setCheckedVideos(filteredVideos);
    }
  }, [categoriesChecked]);

  return (
    <div className={style["explore"]}>
      <CategoriesSelection
        style={{ flex: "0 0 auto" }}
        categoriesChecked={categoriesChecked}
        setCategoriesChecked={setCategoriesChecked}
        filter={filter}
        setFilter={setFilter}
        choosenType={choosenType}
        setChoosenType={setChoosenType}
        videoTypes={videoTypes}
      />
      {displayVideos?.length > 0 ? (
        <div className={style["content"]}>
          <div className={style["video-container"]}>
            <CardGrid
              videosData={displayVideos}
              lastVideoElementRef={lastVideoElementRef}
            />
          </div>
          {isLoadingMore && <LoadingIndicator />}
        </div>
      ) : (
        <div className={style["no-data"]}>
          <img src={NoData} />
          <p>"No Videos Found"</p>
        </div>
      )}
    </div>
  );
};

export default UserBoard;
