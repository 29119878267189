import React, { useState } from "react";
import axios from "axios";
import "./login.module.scss";
import { toast } from "react-toastify";
import { google } from "../../../assets/images";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Modal,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import { useUser } from "../../../state/UserContext";

const LoginPopUp = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  if (window.matchMedia("(max-width: 450px)").matches) {
    style.width = "300px";
  }

  const handleAuthGoogle = async () => {
    try {
      window.open(
        `${process.env.REACT_APP_API_URL}/auth/google/callback`,
        "_self"
      );
    } catch (error) {
      // console.log("error : ",error.message);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { setUser } = useUser();

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email,
          password,
        }
      );
      const { user, token } = data;
      setUser(data);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("isBusinessOwner", false);
      window.location.href = "/";
    } catch (err) {
      toast(err.response.data);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} className="modal">
        <Box sx={style}>
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-primary_text text-[33px] font-[700] mb-8">
              LOGIN
            </h1>
            <form
              onSubmit={loginUser}
              className="w-full flex flex-col gap-4 items-center"
            >
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
                className="w-full mb-4"
                type="email"
              />
              <TextField
                label="Password"
                variant="outlined"
                className="w-full mb-4"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          showPassword ? "Hide password" : "Show password"
                        }
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="w-full">
                <PrimaryButton
                  type="submit"
                  disabled={!email || !password}
                  text="Login to continue"
                  full="w-full"
                />
              </div>
            </form>
            <div className="mt-6 flex flex-col items-center w-full">
              <button
                onClick={handleAuthGoogle}
                className="z-10 flex gap-2 w-full items-center justify-center border border-opacity-30 rounded-[8px] border-primary_text py-[15px] px-[10px] text-primary_text text-[13px] font-[700] cursor-pointer mb-4"
              >
                <img src={google} alt="google" />
                <span>Login with Google</span>
              </button>
              <div className="flex gap-3 mt-4">
                <p className="text-[13px] font-[500]">Don’t have an account?</p>
                <button
                  className="text-primary text-[13px] font-[700] z-10"
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LoginPopUp;
