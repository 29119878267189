import "./App.css";
import LoginPage from "./pages/auth/login-page/LoginPage";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profile from "./pages/dashboard/profile-settings/Profile";
import Layout from "./pages/Layout";
import SignupPage from "./pages/auth/signup-page/SignupPage";
import Board from "./pages/dashboard/board/UserBoard";
import Page404 from "./pages/dashboard/page-404/Page404";
import { UserProvider } from "./state/UserContext";
import ListProduct from "./pages/dashboard/products-list/ListProduct";
import VideoPage from "./pages/dashboard/video-page/VideoPage";
import MySpace from "./pages/dashboard/my-space/MySpace";
import ProductDetails from "./pages/dashboard/product-details/ProductDetails";
import VideoDetails from "./pages/dashboard/video-details/VideoDetails";
import UploadVideo from "./pages/dashboard/upload-video/UploadVideo";
import Matchmaking from "./pages/dashboard/matchmaking/Matchmaking";
import { VideoProvider } from "./state/VideoContext";
import { CameraProvider } from "./state/CameraContext";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";

function App() {

  return (
    <div className="App h-screen">
        <BrowserRouter>
          <UserProvider>
            <VideoProvider>
              <CameraProvider>
                <Routes>

                  <Route path="/" element={<Layout page={<Board />} />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route path="/products-store" element={<Layout page={<ListProduct />} />} />
                  <Route path="/my-space" element={<Layout page={<MySpace />} />} />
                  <Route path="/product-details/:id" element={<Layout page={<ProductDetails />} />} />
                  <Route path="/video-details/:id" element={<Layout page={<VideoDetails />} />} />
                  <Route path="/settings/profile" element={<Layout page={<Profile />} />} />
                  <Route path="/video" element={<Layout page={<VideoPage />} />} />
                  <Route path="/upload-video" element={<Layout page={<UploadVideo />} />} />
                  <Route path="/matchmaking-demo" element={<Layout page={<Matchmaking />} />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                  {/* Not Found Page */}
                  <Route path="*" element={<Page404 />} />

                  {/* Not used routes */}
                  {/* <Route path="/" element={<LandingPage />} /> */}
                  {/* <Route path="/" element={<HomePageV2 />} /> */}
                  {/* <Route path="/record-exemple" element={<RecordPageExample />} /> */}
                  {/* <Route path="/board" element={<Layout page={<Board />} />} /> */}
                  {/* <Route path="/analytics" element={<Layout page={<Analytics />} />} /> */}
                  {/* <Route path="/reports" element={<Layout page={<Reports />} />} />*/}
                  {/* <Route path="/action-log" element={<Layout page={<UserActionLog />} />} /> */}
                  {/* <Route path="/saved-posts" element={<Layout page={<SavedPosts />} />} /> */}
                  {/* <Route path="/saved-products" element={<Layout page={<SavedProducts />} />} /> */}
                  {/* <Route path="/schedule" element={<Layout page={<Schedule />} />} /> */}
                  {/* <Route path="/wishlist" element={<Layout page={<Wishlist />} />} /> */}
                  {/* <Route path="/switch-to-businessowner" element={<Layout page={<SwitchToBusinessOwner />} />} /> */}
                  {/* <Route path="/camera" element={<CameraComponent />} /> */}
                  {/* <Route path="/settings/team" element={<Layout page={<Team />} />} /> */}
                  {/* <Route path="/settings/edit-profile" element={<Layout page={<EditProfile />} />} /> */}
                  {/* <Route path="/create-product" element={<Layout page={<CreateProduct />} />} /> */}
                </Routes>
              </CameraProvider>
            </VideoProvider>
          </UserProvider>
        </BrowserRouter>
    </div>
  );
}

export default App;
