import React from "react";

const PrimaryButton = ({ text, full, onClick ,style}) => {
  return (
    <div>
      <button
        className={`primary-button ${full}`}
        onClick={onClick}
        style = {style}
        // style={{ borderRadius: "29px" , width:"140px"}}
      >
        {text} <span className="button-container"></span>{" "}
      </button>
    </div>
  );
};

export default PrimaryButton;
