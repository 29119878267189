import React, { useState, useEffect, useContext } from 'react';
import { Autocomplete, Modal, TextField } from '@mui/material';
import style from '../create-new-product/createProduct.module.scss';
import { Context, useUser } from '../../state/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { importImage } from '../../assets/images';

const categories = [
  "Influencer marketing", "Productivity", "Community", "SEO", "Spotify",
  "Data", "Advertising", "SaaS", "pitch"
];

export default function UpdateProductModal({ open, onClose, productId }) {
  const [productName, setProductName] = useState("");
  const [tagline, setTagline] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [category, setCategory] = useState("");
  const [pricing, setPricing] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [productImagePath, setProductImagePath] = useState("");
  const [links, setLinks] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { newProduct, setNewProduct, setProductList, productList } = useContext(Context);
  const { userData } = useUser();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);

  const getBusinessOwnerProduct = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products/${productId._id}`
      );
      const response = await res.json();
      setProduct(response);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  useEffect(() => {
    if (productId) {
      getBusinessOwnerProduct();
    }
  }, [productId]);

  useEffect(() => {
    if (product) {
      setProductName(product.name || "");
      setTagline(product.tags[0] || "");
      setWebsiteLink(product.website_link || "");
      setDescription(product.description || "");
      setCategoryName(product.categories[0] || "");
      setCategory(product.category || "");
      setPricing(product.price || "");
      setProductImagePath(product.image || "");
      setProductImage(product.image ? `${process.env.REACT_APP_API_URL}/${product.image}` : null);
      setLinks({
        "Instagram Link": product.instagram_link || "",
        "Facebook Link": product.facebook_link || "",
        "Play Store Link": product.playstore_link || "",
        "App Store Link": product.appstore_link || "",
      });
    }
  }, [product]);

  const resetForm = () => {
    setProductName("");
    setTagline("");
    setWebsiteLink("");
    setDescription("");
    setCategoryName("");
    setCategory("");
    setPricing("");
    setProductImage(null);
    setProductImagePath("");
    setLinks({});
    setFormErrors({});
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleChange = (event, newValue) => {
    setCategoryName(newValue);
    setCategory(newValue);
  };

  const handleImageChange = (event) => {
    setProductImagePath(event.target.files[0]);
    setProductImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    setFormErrors({}); // Reset form errors

    try {
      if (!userData?._id) {
        console.error("User is not authenticated.");
        return;
      }
      const formData = new FormData();
      if (productName) formData.append("name", productName);
      if (category) formData.append("categories", category);
      if (description) formData.append("description", description);
      if (websiteLink) formData.append("website_link", websiteLink);
      if (links["Instagram Link"]) formData.append("instagram_link", links["Instagram Link"]);
      if (links["Facebook Link"]) formData.append("facebook_link", links["Facebook Link"]);
      if (links["Play Store Link"]) formData.append("playstore_link", links["Play Store Link"]);
      if (links["App Store Link"]) formData.append("appstore_link", links["App Store Link"]);
      if (pricing) formData.append("price", isNaN(parseInt(pricing)) ? 0 : parseInt(pricing));
      if (productImagePath) formData.append("image", productImagePath);
      formData.append("business_owner", userData._id);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const data = {
        name: productName, categories: category, description, website_link: websiteLink,image:productImagePath
      };
      console.log("daaaaaata :", data);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/products/update/${productId._id}`,
        data,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      console.log('Update Response:', response.data);

      if (response.data._id === productId._id) {
        const updatedProductList = productList.map((prod) =>
          prod._id === productId._id ? response.data : prod
        );
        setProductList(updatedProductList);
        setNewProduct(newProduct + 1);
        handleClose();
        navigate("/my-space");
      } else {
        console.error("Response data does not match the expected format.");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      if (error.response) {
        console.error(`Status: ${error.response.status}, Data: ${error.response.data}`);
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className={style.modal}>
      <div className={style["modal-content"]}>
        <div className={style["product-info"]}>
          <h1 className={style.header}>Update Product</h1>
          <form className={style.form} onSubmit={handleUpdateProduct}>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Product Name</div>
              <input
                className={style.input}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Tagline</div>
              <input
                className={style.input}
                value={tagline}
                onChange={(e) => setTagline(e.target.value)}
              />
            </div>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Website Link</div>
              <input
                className={style.input}
                placeholder="www.website.com"
                value={websiteLink}
                onChange={(e) => setWebsiteLink(e.target.value)}
              />
            </div>
            <hr className={style.hr} />
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Product Logo</div>
              <img src={productImage || importImage} className={style["product-image"]} alt="Product" />
              <div className={style.input}>
                <input
                  type="file"
                  id="product-image-upload"
                  className={style.hiddenFileInput}
                  onChange={handleImageChange}
                />
                <div>
                  <label htmlFor="product-image-upload" className={style.labelContainer}>
                    <span className={style.uploadLabel}> Click to upload </span>
                    <span className={style.image_size}>
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </span>
                  </label>
                </div>
              </div>
            </div>

            
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Description</div>
              <textarea
                className={style.input}
                rows="4"
                placeholder="Add a short description to your product"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <hr className={style.hr} />
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Category</div>
              <Autocomplete
                id="demo-multiple-name"
                className={style["auto-complete"]}
                value={categoryName}
                onChange={handleChange}
                options={categories}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
              />
            </div>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Pricing</div>
              <input
                className={style.input}
                value={pricing}
                onChange={(e) => setPricing(e.target.value)}
              />
            </div>
            <div className={style.btns}>
              <button className={style.cancel} type="button" onClick={handleClose}>
                Cancel
              </button>
              <button className={style.next} type="submit">
                Update
              </button>
            </div>
      
          </form>
        </div>
      </div>
    </Modal>
  );
}
