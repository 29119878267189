// import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
// import { useUser } from "../../../../state/UserContext";
// import axios from "axios";
// import style from "./listProducts.module.scss";
// import { robo, defaultImage, NoData } from "../../../../assets/images";
// import VideoPlayer from "../../../../components-v2/video-player/VideoPlayer";
// import { CircularProgress, Rating } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import LoginPopUp from "../../../auth/login-page/LoginPopUp";
// import ProductCard from "./ProductCard";

// const ListProduct = ({ searchQuery }) => {
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//   const { userData } = useUser();
//   const [products, setProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 12;
//   const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
//   const [myProductsRatings, setMyProductsRatings] = useState([]);
//   const processedProductsRef = useRef(new Set());
//   const navigate = useNavigate();
//   const [isHovered, setIsHovered] = useState(false);

//   const totalPages = useMemo(() => Math.ceil(products.length / itemsPerPage), [products]);
//   const startIndex = useMemo(() => (currentPage - 1) * itemsPerPage, [currentPage]);
//   const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage, products.length), [startIndex, products.length]);
//   const currentProducts = useMemo(() => products.slice(startIndex, endIndex), [startIndex, endIndex, products]);

//   const LoadingIndicator = () => (
//     <div className="flex justify-center items-center mt-20">
//       <CircularProgress />
//     </div>
//   );

//   const getAllProducts = useCallback(async () => {
//     try {
//       const res = await fetch(`${process.env.REACT_APP_API_URL}/products`);
//       const response = await res.json();
//       const prod = response.filter((video) => video.pin_video);
//       setProducts(prod);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     }
//   }, []);

//   const fetchRating = useCallback(async (product) => {
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_API_URL}/ratings/get-ratings/${product}`);
//       if (res.data) {
//         const userRatings =
//           res.data?.reduce((accumulator, currentRating) => accumulator + currentRating.value, 0) / res?.data.length;
//         if (userRatings) {
//           return { product: product, rating: userRatings };
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching rating:", error);
//       return null;
//     }
//   }, []);

//   useEffect(() => {
//     const fetchAllRatings = async () => {
//       const ratingPromises = currentProducts.map(async (product) => {
//         if (!processedProductsRef.current.has(product._id)) {
//           const ratingData = await fetchRating(product._id);
//           if (ratingData) {
//             processedProductsRef.current.add(product._id);
//             return ratingData;
//           }
//         }
//         return null;
//       });

//       const newRatings = (await Promise.all(ratingPromises)).filter(Boolean);
//       if (newRatings.length > 0) {
//         setMyProductsRatings((prevRatings) => [...prevRatings, ...newRatings]);
//       }
//     };

//     fetchAllRatings();
//   }, [currentProducts, fetchRating]);

//   useEffect(() => {
//     getAllProducts();

//     const handleResize = () => {
//       setScreenWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [getAllProducts]);

//   const handleRateChange = async (val, productId) => {
//     if (val) {
//       const rateIndex = myProductsRatings.findIndex((rate) => rate.product === productId);

//       if (rateIndex !== -1) {
//         try {
//           const res = await axios.put(
//             `${process.env.REACT_APP_API_URL}/ratings/update/${myProductsRatings[rateIndex].rating._id}`,
//             {
//               userId: userData._id,
//               value: val,
//             }
//           );
//           if (res.data) {
//             const updatedRatings = [...myProductsRatings];
//             updatedRatings[rateIndex].rating = res.data;
//             setMyProductsRatings(updatedRatings);
//           }
//         } catch (error) {
//           console.error("Error updating my rate:", error);
//         }
//       } else {
//         try {
//           await axios.post(
//             `${process.env.REACT_APP_API_URL}/ratings/add-ratings-to-product/${productId}`,
//             {
//               userId: userData._id,
//               value: val,
//             }
//           );
//         } catch (error) {
//           console.error("Error submitting new rate:", error);
//         }
//       }
//     }
//   };

//   return (
//     <div>
//       <div className={style["container"]}>
//         <div className={style["header"]}>Products Store</div>
//         {products.length > 0 ? (
//           <div className={style.products}>
//             {currentProducts.map((product) => (
//             <ProductCard  product={product} myProductsRatings={myProductsRatings} userData={userData} setOpenLoginPopUp={setOpenLoginPopUp}  />
//             ))}
//           </div>
//         ) : (
//           <div className={style["no-data"]}>
//             <img src={NoData} alt="No Products Found" />
//             <p>No Products Found</p>
//           </div>
//         )}
//       </div>
//       <LoginPopUp open={openLoginPopUp} handleClose={() => setOpenLoginPopUp(false)} />
//     </div>
//   );
// };
// export default ListProduct;

import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useUser, Context } from "../../../state/UserContext";
import axios from "axios";
import style from "./listProducts.module.scss";
import { NoData } from "../../../assets/images";
import ProductCard from "./ProductCard";
import { CircularProgress } from "@mui/material";
import LoginPopUp from "../../auth/login-page/LoginPopUp";

const ListProduct = ({ searchQuery }) => {
  const { userData } = useUser();
  const { products, setProducts } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [myProductsRatings, setMyProductsRatings] = useState([]);
  const [page, setPage] = useState(1);
  const processedProductsRef = useRef(new Set());
  const lastProductRef = useRef(null);

  const PRODUCTS_PER_PAGE = 12;

  const LoadingIndicator = () => (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <CircularProgress />
    </div>
  );

  // Fetch products with pagination
  const getAllProducts = useCallback(async () => {
    try {
      setIsFetchingMore(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products?page=${page}&limit=${PRODUCTS_PER_PAGE}`
      );
      const response = await res.json();
      const newProducts = response.filter((product) => product.pin_video);

      // Filter out duplicates
      const uniqueProducts = newProducts.filter(
        (product) => !processedProductsRef.current.has(product._id)
      );

      // Add new products to the set
      uniqueProducts.forEach((product) =>
        processedProductsRef.current.add(product._id)
      );

      setProducts((prevProducts) => [...prevProducts, ...uniqueProducts]);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      if (page === 1) {
        setIsLoading(false);
      }
      setIsFetchingMore(false);
    }
  }, [page, setProducts]);

  // Intersection Observer logic
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !isFetchingMore) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (lastProductRef.current) {
      observer.observe(lastProductRef.current);
    }

    return () => {
      if (lastProductRef.current) {
        observer.unobserve(lastProductRef.current);
      }
    };
  }, [isFetchingMore]);

  // Fetch products when the page changes
  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  return (
    <div>
      <div className={style["container"]}>
        <div className={style["header"]}>Products Store</div>
        {isLoading ? (
          <LoadingIndicator />
        ) : products.length > 0 ? (
          <div className={style.products}>
            {products.map((product, index) => {
              if (index === products.length - 1) {
                return (
                  <div key={product._id}>
                    <ProductCard
                      product={product}
                      myProductsRatings={myProductsRatings}
                      userData={userData}
                      setOpenLoginPopUp={setOpenLoginPopUp}
                      lastProductRef={lastProductRef}
                    />
                  </div>
                );
              }
              return (
                <ProductCard
                  key={product._id}
                  product={product}
                  myProductsRatings={myProductsRatings}
                  userData={userData}
                  setOpenLoginPopUp={setOpenLoginPopUp}
                />
              );
            })}
          </div>
        ) : (
          <div className={style["no-data"]}>
            <img src={NoData} alt="No Products Found" />
            <p>No Products Found</p>
          </div>
        )}
      </div>
      {isFetchingMore && <LoadingIndicator />}
      <LoginPopUp
        open={openLoginPopUp}
        handleClose={() => setOpenLoginPopUp(false)}
      />
    </div>
  );
};

export default ListProduct;
