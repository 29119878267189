// VideoContext.js

import React, { createContext, useState } from "react";

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [trimmedVideoFile, setTrimmedVideoFile] = useState(null);

  return (
    <VideoContext.Provider value={{ trimmedVideoFile, setTrimmedVideoFile }}>
      {children}
    </VideoContext.Provider>
  );
};
