import React, { useEffect, useRef, useState } from "react";
import { Slider, CircularProgress, Typography } from "@material-ui/core";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import VideoPlayer from "./VideoPlayer";
import style from "./video-editor.module.scss";
import { scissor, trashVideo, itemBackword, itemForward } from "../../assets/images";
const ffmpeg = createFFmpeg({ log: true });

function VideoEditor({ videoFile, showSlider, setFirstFrame }) {
  const [ffmpegLoaded, setFFmpegLoaded] = useState(false);
  const [videoPlayerState, setVideoPlayerState] = useState([]);
  const [videoPlayer, setVideoPlayer] = useState();
  const [resultUrl, setResultUrl] = useState();
  const [frames, setFrames] = useState([]);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [processing, setProcessing] = useState(false);
  const prevVideoFile = useRef(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const styles = {
    borderRadius: '8px',
    border: '1px solid #f6552d',
    background: '#f6552d',
    width: "150px", height: "auto", marginLeft: "15px", marginTop
    : "10px"
  };

  const loadFFmpeg = async () => {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }
    setFFmpegLoaded(true);
  };

  useEffect(() => {
    loadFFmpeg();
  }, []);

  useEffect(() => {
    if (videoFile && ffmpegLoaded && prevVideoFile.current !== videoFile) {
      console.log("++++++=this is the video file +++++++", videoFile.duration);
      prevVideoFile.current = videoFile;
      setSliderValue([0, 0]);
      setResultUrl(undefined);
      extractFrames(videoFile);
    }
  }, [videoFile, ffmpegLoaded]);

  const extractFrames = async (videoFile) => {
    if (!ffmpegLoaded || !videoFile) return;
    setProcessing(true);
    try {
      const data = await fetchFile(videoFile);
      ffmpeg.FS("writeFile", "input.mp4", data);
      const video = document.createElement('video');
      video.src = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
      video.onloadedmetadata = async () => {
        if (video.duration === Infinity) {
        // Force reload the metadata
        video.currentTime = 1e101;
        video.ontimeupdate = async () => {
        video.ontimeupdate = null;
        console.log(video.duration);
        setVideoDuration(video.duration);
        setSliderValue([0, video.duration]);
        // Continue with processing frames
        await ffmpeg.run("-i", "input.mp4", "-vf", "fps=1", "frame_%03d.jpg");
        const frameFiles = ffmpeg.FS("readdir", "/").filter(file => file.startsWith("frame_"));
        const frameUrls = frameFiles.map(file => {
        const frame = ffmpeg.FS("readFile", file);
        const frameBlob = new Blob([frame.buffer], { type: "image/jpeg" });
        return URL.createObjectURL(frameBlob);
        });
        setFrames(frameUrls);
        setProcessing(false);
        };
        } else {
        console.log(video.duration);
        setVideoDuration(video.duration);
        setSliderValue([0, video.duration]);
        
        // Continue with processing frames
        await ffmpeg.run("-i", "input.mp4", "-vf", "fps=1", "frame_%03d.jpg");
        const frameFiles = ffmpeg.FS("readdir", "/").filter(file => file.startsWith("frame_"));
        const frameUrls = frameFiles.map(file => {
        const frame = ffmpeg.FS("readFile", file);
        const frameBlob = new Blob([frame.buffer], { type: "image/jpeg" });
        return URL.createObjectURL(frameBlob);
        });
        setFrames(frameUrls);
        setProcessing(false);
        }
        };
    } catch (error) {
      console.error("Error extracting frames:", error);
      setProcessing(false);
    }
  };

  const handleTrim = async () => {
    setProcessing(true);
    try {
      const [start, end] = sliderValue;
      const validatedStartSeconds = Math.max(0, start);
      const validatedEndSeconds = Math.min(videoDuration, end);
      if (!isFinite(validatedStartSeconds) || !isFinite(validatedEndSeconds)) {
        setProcessing(false);
        return;
      }
      await ffmpeg.run(
        "-i", "input.mp4",
        "-ss", `${validatedStartSeconds}`,
        "-to", `${validatedEndSeconds}`,
        "-c:v", "copy",
        "-c:a", "copy",
        "output.mp4"
      );
      const files = ffmpeg.FS("readdir", "/");
      if (!files.includes("output.mp4")) {
        setProcessing(false);
        return;
      }
      const data = ffmpeg.FS("readFile", "output.mp4");
      const videoBlob = new Blob([data.buffer], { type: "video/mp4" });
      const videoUrl = URL.createObjectURL(videoBlob);
      setResultUrl(videoUrl);
      window.localStorage.setItem("trimmedVideoUrl", videoUrl);
      setProcessing(false);
    } catch (error) {
      console.error("Error trimming video:", error);
      setProcessing(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (videoDuration) {
      setSliderValue([0, videoDuration]);
    }
  }, [videoDuration]);

  return (
    <div style={{ position: "relative" }}>
      <CircularProgress
        style={{
          visibility: processing || !ffmpegLoaded ? "visible" : "hidden",
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: "1000",
        }}
        size={60}
      />
      <div
        style={{
          visibility: processing || !ffmpegLoaded ? "visible" : "hidden",
          position: "absolute",
          zIndex: "1000",
          top: "60%",
          left: "50%",
          transform: "translateX(-50%)",
          color: "white",
          backgroundColor: "black",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        Please wait, we are processing the video...
      </div>
      <div className="player-video">
        <VideoPlayer
          key={videoFile}
          src={videoFile}
          onPlayerChange={setVideoPlayer}
          onChange={setVideoPlayerState}
          onTimeUpdate={(time) => setCurrentTime(time)}
        />
      </div>
      {showSlider && frames.length > 0 && (
        <div className={style["slider-section"]}>
          <div className={style["operation-section"]}>
            <div className={style["operations"]}>
              <div>
                <img src={scissor} onClick={handleTrim} />
              </div>
              <div>
                <a
                  href={resultUrl}
                  download="trimmed_video.mp4"
                >
                  <img src={itemBackword} />
                </a>
              </div>
              <div>
                <img src={itemForward} />
              </div>
              <div>
                <img
                  src={trashVideo}
                  onClick={() => setResultUrl(undefined)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
            <div className={style["video-duration"]}>
              <span>{formatDuration(0)} / {formatDuration(videoDuration)}</span>
            </div>
          </div>
          {videoDuration > 0 && (
            <Slider
              value={sliderValue}
              onChange={(event, value) => setSliderValue(value)}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              min={0}
              max={videoDuration}
              thumb={
                <div style={{ position: "absolute", left: `${(currentTime / videoDuration) * 100}%`, transform: 'translateX(-50%)' }}>
                  <span style={{ background: '#f6552d', color: 'white' }}>
                    {formatTime(currentTime)}
                  </span>
                </div>
              }
              marks={frames.map((frame, index) => ({
                value: (index / frames.length) * videoDuration,
                label: (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                      variant="caption"
                      style={{
                        position: "absolute",
                        height: "20px",
                        width: "1px",
                        marginRight: "10px",
                        backgroundColor: "#aaa",
                        fontSize: "12px",
                        top: "0",
                      }}
                    >
                      {formatTime((index / frames.length) * videoDuration)}
                    </Typography>
                    <div className={style["frame-label"]}>
                      <img src={frame} alt={`frame-${index}`} className={style["frame-image"]} />
                    </div>
                  </div>
                ),
              }))}
            />
          )}

        </div>
      )}

      {resultUrl && (
        <div className="result-video" style={{ marginTop: "20px" }}>
          <video controls key={resultUrl} style={{ width: "100%", maxHeight: "500px", padding: "30px" }} className={style["video"]}>
            <source src={resultUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  );
}

export default VideoEditor;
