import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { Context, useUser } from "../../../state/UserContext";
import style from "./matchmaking.module.scss";
import { product, redHeart, grayHeart, cross } from "../../../assets/images";
import card2 from "./card/card";
function Matchmaking() {
  const [products, setProducts] = React.useState([]);
  const { userData } = useUser();
  const {
    userQuery,
    setUserQuery,
    tags,
    setTags,
    mood,
    setMood,
    goal,
    setGoal,
    interest,
    setInterest,
  } = React.useContext(Context);

  const fetchPromptResponse = async () => {
    try {
      let finalResponse = [];
      let finalTags = [];
      let strQuery = "";
      if (userQuery === "") {
        if (mood !== "") {
          strQuery = `my mood is ${mood}, `;
        }
        if (goal !== "") {
          strQuery = strQuery + `my goal is ${goal}, `;
        }
        if (interest) {
          strQuery = strQuery + `my interest is ${interest}`;
        }
        const response = await fetchPreferencesResponse(`${strQuery}`);
        setProducts(response.data.candidates);
        setTags(response.data.tags);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/ai-demo/recommendation`,
          { prompt: userQuery, nbr_of_products: 30, userId: userData._id }
        );
        if (response) {
          setProducts(response.data.candidates);
          setTags(response.data.tags);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPreferencesResponse = async (query) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ai-demo/recommendation`,
        { prompt: query, nbr_of_products: 30, userId: userData._id }
      );
      if (response) return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPromptResponse();
  }, [userQuery, mood, interest, goal]);

  // useEffect(() => {
  //   fetchPromptResponse()
  // }, [mood, interest, goal])
  function sentNewQuery(tag) {
    setUserQuery(userQuery + ` no and remove ${tag}`);
  }
  return (
    // <div>
    //   <Box sx={{ flexGrow: 1 }}>
    //     <Grid
    //       container
    //       spacing={{ xs: 2, md: 3 }}
    //       columns={{ xs: 4, sm: 8, md: 12 }}
    //     >
    //       {products.map((product, index) => (
    //         <Grid item xs={2} sm={4} md={4} key={index}>
    //           <ProducutCard image={product.image} productName={product.productName} productDecription={product.overview} />
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Box>
    // </div>
    <div>
      <div className={style["tags-container"]}>
        {tags?.map((tag, index) => (
          <div className={style["tag-container"]} key={index}>
            <div className={style["tag"]}>{tag}</div>
            <img
              style={{ margin: " 0px 10px", cursor: "pointer" }}
              src={cross}
              onClick={() => sentNewQuery(tag)}
            ></img>
          </div>
        ))}
      </div>
      <div className={style["grid"]}>
        {products.length != 0 &&
          products?.map((product, index) => {
            return (
              <React.Fragment key={index}>
                <div>
                  <ProducutCard
                    image={product?.image}
                    productName={product?.productName}
                    productDecription={product?.overview}
                    // link={product.link}
                    index={index}
                  />
                  {/* <card2 index={index}
                  productName={product?.productName}

                  productImage={product?.image}
                  textPreview={product?.overview}
                  videoId={index}
                ></card2> */}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}

function ProducutCard(props) {
  const { image, productName, productDecription, link, index } = props;

  return (
    <div key={index} className={style["cardProduct"]}>
      <Card sx={{ maxWidth: "100%" }}>
        <CardActionArea>
          <CardMedia
            component="img"
            sx={{ height: 240 }}
            image={image}
            alt={productName}
          />
          <CardContent>
            <Typography variant="h7" component="div">
              {productName}
            </Typography>
            {/* <div className={style["like-button"]}>
              <img src={redHeart} alt="like button" className={style["heart"]} />
            </div> */}
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
export default Matchmaking;
