import React, { useEffect, useState, useContext, useRef } from "react";
import style from "./header.module.scss";
import { CiSearch } from "react-icons/ci";
import { MenuContext } from "../../pages/Layout";
import { useLocation, useNavigate } from "react-router";
import { useUser } from "../../state/UserContext";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { Context } from "../../state/UserContext";
import ProductsPopUp from "../products-popups/ProductsPopUp";
import aiIcon from "../../assets/images/ai.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PopupState from "material-ui-popup-state";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import {
  emojiconfused,
  emojistressed,
  excitedEmoji,
  curiousEmoji,
  neutralEmoji,
  bot,
  history,
  nav_send,
  close,
  xIcon,
} from "../../assets/images/index";
import { Box, CircularProgress, Modal, Stack } from "@mui/material";
const Header = () => {
  const { userData } = useUser();
  const [tempQuery, setTemQuery] = useState("");
  const navigate = useNavigate();
  const [userHistory, setUserHistory] = useState([]);
  const { allVideos, setAllVideos } = useContext(Context);
  const { products, setProducts } = useContext(Context);

  const inputWrapperRef = useRef(null);
  const [popoverWidth, setPopoverWidth] = useState("672 px");
  const { openProductPopUp, openChooseProduct, userQuery, setUserQuery } =
    useContext(Context);
  const [nextActive, setnextActive] = useState(true);

  const [openSidebar, setOpenSidebar, mobileScreen, setMobileScreen] =
    useContext(MenuContext);
  const [mediumScreen, setMediumScreen] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [open, setOpen] = useState(false);
  const [openRecord, setOpenRecord] = useState(false);
  const [openCreateVideo, setOpenCreateVideo] = useState(false);
  const { productList, setProductList } = useContext(Context);
  const { userProductList, setUserProductList } = useContext(Context);
  const {
    displayVideos,
    setDisplayVideos,
    mood,
    setMood,
    goal,
    setGoal,
    interest,
    setInterest,
  } = useContext(Context);
  const [searchQuery, setSearchQuery] = useState("");
  // const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const precedentQuestion = localStorage.getItem("precedentQuery");
  const isProfileSettingsPage =
    location.pathname.includes("/settings/profile") ||
    location.pathname.includes("/product-details");
  // const isAiMatchingPage = location.pathname.includes("matchmaking-demo");
  // const [searchTags, setSearchTags] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const handleClickAIButton = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(!openPopover);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  const openQuestionPrecedent = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const propmts = localStorage
    ?.getItem("precedentQuery")
    ?.split(",")
    .map((word) => word.trim());
  const handleClickQuestionPrecedent = (event) => {
    if (propmts !== "") setAnchorEl(event.currentTarget);
    setShowHistory(!showHistory);
  };

  const handleCloseQuestionPrecedent = () => {
    setAnchorEl(null);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/products`);
      const response = await res.json();
      const filteredResults = response.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setHistories(filteredResults);
      // setSearchResults(filteredResults);
      setProductList(filteredResults);
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/products/business-owner-products/${userData._id}`
      );
      const finalresult = await result.json();
      const filteredUserProducts = finalresult.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setUserProductList(filteredUserProducts);

      const filteredVideos = displayVideos.filter((video) =>
        video.productName.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setDisplayVideos(filteredVideos);
    } catch (error) {
      console.error("Error fetching or filtering data:", error);
    }
  };
  const handleChange = (e) => {
    setTemQuery(e.target.value);
    // handleSearch(e);
  };
  const MENU_OPTIONS = [{ title: "Settings", route: "/settings/profile" }];
  const USER_MENU_OPTIONS = [
    { title: "Home", route: "/" },
    { title: "Settings", route: "/settings/profile" },
  ];

  // const SEARCH_HISTORY = ["I want productivity app", "App for learning"];
  const [histories, setHistories] = useState([]); // SEARCH_HISTORY

  const deleteHistory = async (itemToDelete, indexToDelete) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/history/delete-history`,
        {
          data: {
            content: itemToDelete.text,
            userId: userData._id,
          },
        }
      );

      if (response.status === 200) {
        console.log("Item deleted:", response.data.message);

        const updatedItems = histories.filter(
          (_, index) => index !== indexToDelete
        );
        setHistories(updatedItems);
      }
    } catch (error) {
      console.error(
        "Failed to delete item:",
        error.response?.data || error.message
      );
    }
  };

  const [tags, setTags] = useState([]);

  const deleteTag = (indexToDelete) => {
    const updatedTags = tags.filter((_, index) => index !== indexToDelete);
    setTags(updatedTags);
  };

  const fetchSearchTags = async () => {
    try {
      console.log("keyworddds");
      let searchResult = [];
      let context = "";
      if (location.pathname.includes("products-store")) {
        console.log("keyworddds P");
        searchResult = products;
        context = localStorage.getItem("productSearchContext");
      } else {
        searchResult = displayVideos;
        context = localStorage.getItem("videoSearchContext");
      }
      console.log(searchResult);
      searchResult.map((video) => {
        if (video?.embeddings) {
          delete video.embeddings;
        } else if (video?.product?.embeddings) {
          delete video.product.embeddings;
        }
        return video;
      });
      console.log("searchResult", searchResult);
      console.log("conntext", context);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/natural-matchmaking/generate-keywords`,
        {
          context: context,
          result: searchResult,
        }
      );
      if (res) {
        const response = res.data.keywords;
        const limitedTags =
          response.length > 5 ? response.slice(0, 5) : response;

        setTags(limitedTags);
        console.log("keywords", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchuserSearchHistory = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/history/fetch-search-history/${userData._id}`
      );
      const response = await res.json();
      if (response) {
        // setUserHistory(response)
        setHistories(response);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchuserSearchHistory();
    fetchSearchTags();
  }, []);

  const HandleOpenSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleResize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    if (window.innerWidth < 1235) {
      setMediumScreen(true);
    } else {
      setMediumScreen(false);
      setOpenSidebar(false);
    }
    if (window.innerWidth < 500) {
      setMobileScreen(true);
    } else {
      setMobileScreen(false);
      setOpenSidebar(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenRecord = () => {
    setOpenRecord(!openRecord);
  };
  const handleCloseRecord = () => {
    setOpenRecord(null);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logout = async () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const [isLoadingProduct, setIsLoadingProducts] = useState(false);

  const getBusinessOwenerProducts = async () => {
    setIsLoadingProducts(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products/business-owner-products/${userData._id}`
      );
      const response = await res.json();
      setProductList(response);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoadingProducts(false);
    }
  };
  useEffect(() => {
    if (userData != null) {
      getBusinessOwenerProducts();
    }
  }, []);

  // function navigateToMatchmaking() {
  //   if (tempQuery !== "")
  //     localStorage.setItem(
  //       "precedentQuery",
  //       precedentQuestion + ", " + tempQuery
  //     );
  //   if (localStorage.getItem("precedentQuery") !== "")
  //     setUserQuery(localStorage.getItem("precedentQuery"));
  //   else setUserQuery(tempQuery);

  //   navigate("/matchmaking-demo");
  // }

  // function handleRest() {
  //   localStorage.setItem("precedentQuery", "");
  //   setUserQuery("");
  //   setTemQuery("");
  // }
  // function goToMatchingPage() {
  //   localStorage.setItem("activeItem", "matching");
  //   navigate("/matchmaking-demo");
  // }

  const [loading, setLoading] = useState(false);

  const handleSearchAi = async () => {
    setLoading(true);
    try {
      if (location.pathname.includes("products-store")) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/natural-matchmaking/products-recommendation`,
          {
            context: localStorage.getItem("context") || "",
            query: tempQuery,
            userId: userData._id,
          }
        );
        const response = res?.data.result;
        const context = res?.data.context;
        if (response) {
          const prod = response.filter((video) => video.pin_video);
          setProducts(prod);
        }
        localStorage.setItem("productSearchContext", context);
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/natural-matchmaking/videos-recommendation`,
          {
            context: localStorage.getItem("videoSearchContext") || "",
            query: tempQuery,
            userId: userData._id,
          }
        );
        const response = res?.data.result;
        const context = res?.data.context;

        if (response) {
          console.log(response);
          sessionStorage.setItem("aiVideosData", JSON.stringify(response));

          setDisplayVideos(response);
          console.log("setDisplayVideos", response);
          setAllVideos(response);
          localStorage.setItem("videoSearchContext", context);
        }
      }
      await fetchSearchTags();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const resetSearchContext = async () => {
    sessionStorage.setItem("aiVideosData", JSON.stringify([]));
    localStorage.setItem("videoSearchContext", "");
    localStorage.setItem("productSearchContext", "");
    setTemQuery("");
    setTags([]);
    // setSearchTags([])
    window.dispatchEvent(new Event("storage"));
  };
  const eleminateTag = async (eleminatedTag) => {
    try {
      if (location.pathname.includes("products-store")) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/natural-matchmaking/products-recommendation`,
          {
            context: localStorage.getItem("context") || "",
            query: `remove ${eleminatedTag}`,
            userId: userData._id,
          }
        );
        const response = res?.data.result;
        if (response) {
          const prod = response.filter((video) => video.pin_video);
          setProducts(prod);
        }
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/natural-matchmaking/videos-recommendation`,
          {
            context: localStorage.getItem("videoSearchContext") || "",
            query: `remove ${eleminatedTag}`,
            userId: userData._id,
          }
        );
        const response = res?.data.result;
        if (response) setDisplayVideos(response);
      }
      // await fetchSearchTags()
    } catch (error) {}
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // navigateToMatchmaking();
      handleSearchAi();
    }
  };

  const popoverRef = useRef(null);
  const recordRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      recordRef.current &&
      !recordRef.current.contains(event.target)
    ) {
      setOpenRecord(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={style["header"]}>
      <div className={style["container-view"]}>
        <div className={style["search-container"]}>
          {userData && !isProfileSettingsPage && (
            <div className={style["search-bar-container"]}>
              {window.location.pathname == "/" ||
              window.location.pathname == "/products-store" ? (
                <div className={style["search-bar-div"]}>
                  <div className={style["search-bar-box"]}>
                    <div
                      className={style["input-wrapper"]}
                      onClick={handleClickQuestionPrecedent}
                      ref={inputWrapperRef}
                    >
                      {/* <GrPowerReset
                          onClick={handleRest}
                          size={24}
                          color={
                            localStorage.getItem("precedentQuery") == ""
                              ? "#A9A9A9"
                              : "#F6552D"
                          }
                          style={{ cursor: "pointer" }}
                        /> */}

                      <img src={bot} alt="bot" className="bot" />

                      <input
                        placeholder="Ask a Question"
                        value={tempQuery}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                      ></input>
                      <div
                        // onClick={navigateToMatchmaking}
                        style={{
                          // marginRight: "5px",
                          // marginBottom: "6px",
                          cursor: "pointer",
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <img
                            className="send"
                            src={nav_send}
                            onClick={handleSearchAi}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    </div>

                    {/* Search history */}
                    <div
                      className={style["search-history-container"]}
                      style={{
                        display: showHistory ? "block" : "none",
                        opacity: 1,
                      }}
                    >
                      <div className={style["search-history"]}>
                        {histories.slice(0, 2).map((history, index) => (
                          <div key={index} className={style["history-item"]}>
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setTemQuery(history.text);
                              }}
                            >
                              {history.text}
                            </p>
                            <button
                              onClick={() => {
                                deleteHistory(history.text, index);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                              >
                                <path
                                  d="M3.79163 3.79163L9.20829 9.20829M3.79163 9.20829L9.20829 3.79163"
                                  stroke="#ADA7A7"
                                  stroke-width="1.08333"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className={style["history-tags"]}>
                        {tags.map((tag, index) => (
                          <div key={index} className={style["tag"]}>
                            <span>{tag}</span>
                            <button
                              onClick={() => {
                                eleminateTag(tag);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="9"
                                viewBox="0 0 10 9"
                                fill="none"
                              >
                                <path
                                  d="M7.22002 2.42004L3.02002 6.62004M3.02002 2.42004L7.22002 6.62004"
                                  stroke="#98A2B3"
                                  stroke-width="1.26"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* ++++++++++++ */}

                    <div className={style["search-bar-bottom"]}>
                      <div className={style["search-bar-bottom-left"]}>
                        <button
                          className={style["search-bar-history-btn"]}
                          onClick={() => {
                            handleOpenModal();
                          }}
                        >
                          <img src={history} alt="history" />
                          <span>History</span>
                        </button>
                        <div className={style["search-bar-bar"]}></div>
                        <button
                          className={style["search-bar-reset"]}
                          onClick={resetSearchContext}
                        >
                          Reset
                        </button>
                      </div>
                      <button
                        className={style["search-bar-history-btn"]}
                        onClick={handleClickAIButton}
                      >
                        <img
                          src={aiIcon}
                          alt="Matching"
                          width="20px"
                          height="20px"
                        />
                        <span>AI Matching </span>
                      </button>
                    </div>
                  </div>

                  {/* {propmts && (
                    <Popover
                      id={id}
                      open={openQuestionPrecedent}
                      anchorEl={anchorEl}
                      onClose={handleCloseQuestionPrecedent}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      style={{ marginTop: "6px" }}
                    >
                      <Typography sx={{ p: 2, width: "40vw " }}>
                        {propmts[propmts.length - 1]}
                      </Typography>
                    </Popover>
                  )} */}
                </div>
              ) : (
                <div></div>
              )}

              <Calendar
                setMood={setMood}
                mood={mood}
                interest={interest}
                setInterest={setInterest}
                goal={goal}
                setGoal={setGoal}
                mobileScreen={mobileScreen}
                openPopover={openPopover}
                handleClosePopover={handleClosePopover}
                anchorEl={anchorEl}
                setProducts={setProducts}
                setDisplayVideos={setDisplayVideos}
                userData={userData}
              ></Calendar>
            </div>
          )}
        </div>
        {(openProductPopUp || openChooseProduct) && (
          <div className={style["product-pop-up"]}>
            <ProductsPopUp
              nextActive={nextActive}
              productList={productList}
              loading={isLoadingProduct}
            />
          </div>
        )}

        <div className={style["header-left"]}>
          {userData && (
            <>
              {/* <img
                src={icon_record}
                className={style["record"]}
                alt="record"
                onClick={handleOpenRecord}
                ref={recordRef}
              /> */}

              {/* <div
                className={style["popover"]}
                style={{ display: openRecord ? "block" : "none" }}
                ref={popoverRef}
              >
                <ModalContent
                  openRecord={openRecord}
                  closeRecord={handleOpenRecord}
                />
              </div> */}

              {/* <Popover
                open={!!openRecord}
                anchorEl={openRecord}
                onClose={handleCloseRecord}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                slotProps={{
                  paper: {
                    sx: {
                      py: 1,
                      mt: 1,
                      width: 160,
                      borderRadius: "12px",
                      boxShadow:
                        "0px 0px 29.143px 0px rgba(191, 200, 208, 0.50)",
                    },
                  },
                }}
              >
                <> */}
              {/* <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#3D3D3C",
                    }}
                    onClick={() => {}}
                  >
                    <img src={rec_icon} alt="rec" width="30px" height="30px" />
                    Create Video
                  </MenuItem> */}

              {/* <ModalContent
                    openRecord={openRecord}
                    closeRecord={handleCloseRecord}
                  /> */}

              {/* <Divider sx={{ borderStyle: "dashed", m: 0 }} /> */}

              {/* <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      marginLeft: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#3D3D3C",
                    }}
                    onClick={() => {}}
                  >
                    <img
                      src={import_video_icon}
                      width="22px"
                      height="22px"
                      alt="import"
                    />
                    Import Video
                  </MenuItem> */}
              {/* </>
              </Popover> */}
            </>
          )}

          {userData ? (
            <div className={style["notif-avatar-container"]}>
              <button onClick={handleOpen}>
                <div className={style["avatar"]}>
                  {/* {userData && userData.picture.includes("uploads") ? (
                    <img
                      alt="profile-picture"
                      name="picture"
                      src={`${process.env.REACT_APP_API_URL}/${userData?.picture}`}
                    />
                  ) : (
                    <div className={style["initials"]}>
                      {userData?.firstName.slice(0, 1).toUpperCase()}
                      {userData?.lastName.slice(0, 1).toUpperCase()}
                    </div>
                  )} */}
                  {console.log(userData.picture)}
                  {userData.picture !== "" || userData.picture ? (
                    userData.picture.includes("uploads") ? (
                      <img
                        alt="profile-picture"
                        name="picture"
                        src={`${process.env.REACT_APP_API_URL}/${userData?.picture}`}
                      />
                    ) : (
                      <img
                        alt="profile-picture"
                        name="picture"
                        src={`${userData?.picture}`}
                      />
                    )
                  ) : (
                    <div className={style["initials"]}>
                      {userData?.firstName.slice(0, 1).toUpperCase()}
                      {userData?.lastName.slice(0, 1).toUpperCase()}
                    </div>
                  )}
                </div>
              </button>
              <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                slotProps={{
                  paper: {
                    sx: {
                      py: 0.5,
                      mt: 1,
                      ml: 0.75,
                      width: 180,
                      borderRadius: "12px",
                      boxShadow:
                        "0px 0px 29.143px 0px rgba(191, 200, 208, 0.50)",
                    },
                  },
                }}
              >
                {userData
                  ? MENU_OPTIONS.map((option) => (
                      <MenuItem
                        key={option.title}
                        onClick={() => navigate(option.route)}
                      >
                        {option.title}
                      </MenuItem>
                    ))
                  : USER_MENU_OPTIONS.map((option) => (
                      <MenuItem
                        key={option.title}
                        onClick={() => navigate(option.route)}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                <Divider sx={{ borderStyle: "dashed", m: 0 }} />

                <MenuItem
                  disableRipple
                  disableTouchRipple
                  onClick={logout}
                  sx={{ typography: "body2", color: "error.main", py: 1.5 }}
                >
                  Logout
                </MenuItem>
              </Popover>
            </div>
          ) : (
            <div className={style["header-connect-wrapper"]}>
              <div className={style["btns-wrapper"]}>
                <button
                  className={style["login-button"]}
                  onClick={() => navigate("/login")}
                >
                  <span>Login</span>
                </button>
                <button
                  className={style["sign-up-button"]}
                  onClick={() => navigate("/signUp")}
                >
                  <span>Sign Up</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <HistoryModal
        open={openModal}
        handleClose={handleCloseModal}
        // historyItems={USER_HISTORY}
        historyItems={histories}
        userId={userData?._id}
      />
    </div>
  );
};

export default Header;

function Calendar(props) {
  const [moodOpen, setMoodOpen] = useState(false);
  const [goalOpen, setGoalOpen] = useState(false);
  const [interestOpen, setInterestOpen] = useState(false);
  const {
    mood,
    setMood,
    goal,
    setGoal,
    interest,
    setInterest,
    mobileScreen,
    openPopover,
    handleClosePopover,
    anchorEl,
    setProducts,
    setDisplayVideos,
    userData,
  } = props;

  const moodlist = [
    { text: "Excited", icon: excitedEmoji },
    { text: "Curious", icon: curiousEmoji },
    { text: "Neutral", icon: neutralEmoji },
    { text: "Stressed", icon: emojistressed },
    { text: "Confused", icon: emojiconfused },
  ];

  const goalList = [
    "Career Growth",
    "Work-Life Balance",
    "Networking",
    "Social Media Presence",
    "Design Website",
    "Be Healthy",
    "Community And Social Causes",
    "Improve Fitness",
  ];

  const interestList = [
    "Advertising",
    "Productivity",
    "Games",
    "Hiring",
    "Tech",
    "extensions",
    "Surfing",
    "Artifical Intelligence",
    "marketing",
    "Design",
    "Saas",
    "Education",
    "Health and fitness",
    "Analytics",
    "Social media",
  ];

  const location = useLocation();
  const handleMoodChange = (event) => setMood(event.target.value);
  const handleMoodClose = () => setMoodOpen(false);
  const handleMoodOpen = () => setMoodOpen(true);

  const handleGoalChange = (event) => setGoal(event.target.value);
  const handleGoalClose = () => setGoalOpen(false);
  const handleGoalOpen = () => setGoalOpen(true);

  const handleInterestChange = (event) => setInterest(event.target.value);
  const handleInterestClose = () => setInterestOpen(false);
  const handleInterestOpen = () => setInterestOpen(true);

  const handleCancel = () => {
    setMood("");
    setGoal("");
    setInterest("");
  };

  const handleSubmit = () => {
    // console.log("Mood:", mood);
    // console.log("Goal:", goal);
    // console.log("Interest:", interest);
    handleMatchMakingAi();
  };

  const handleMatchMakingAi = async () => {
    try {
      if (location.pathname.includes("products-store")) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/natural-matchmaking/products-recommendation`,
          {
            context: "",
            query: `My Mood is ${mood}, My Interest is ${interest}, My Goal is ${goal}`,
            userId: userData._id,
          }
        );
        const response = res?.data.result;
        if (response) {
          const prod = response.filter((video) => video.pin_video);
          setProducts(prod);
        }
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/natural-matchmaking/videos-recommendation`,
          {
            context: "",
            query: `My Mood is ${mood}, My Interest is ${interest}, My Goal is ${goal}`,
            userId: userData._id,
          }
        );
        const response = res?.data.result;
        if (response) setDisplayVideos(response);
      }
    } catch (error) {}
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          {/* <Button
            variant="contained"
            {...bindTrigger(popupState)}
            className={style["matching-ai-button"]}
          >
            <img src={aiIcon} alt="ai" width="37px" height="37px"></img>
          </Button> */}
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              sx: {
                width: "410px",
                "@media (max-width:600px)": {
                  width: "90%",
                  maxWidth: "none",
                },
                mt: "10px",
                p: "20px",
              },
            }}
          >
            <Stack spacing={2}>
              <Button
                variant="text"
                sx={{
                  alignSelf: "flex-end",
                  gap: "5px",
                  color: "#3F3737",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "1px",
                }}
                onClick={() => {}}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 8C13.8674 8 13.7402 8.05268 13.6464 8.14645C13.5527 8.24021 13.5 8.36739 13.5 8.5V13C13.5 13.1326 13.4473 13.2598 13.3536 13.3536C13.2598 13.4473 13.1326 13.5 13 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V3C2.5 2.86739 2.55268 2.74021 2.64645 2.64645C2.74021 2.55268 2.86739 2.5 3 2.5H7.5C7.63261 2.5 7.75979 2.44732 7.85355 2.35355C7.94732 2.25979 8 2.13261 8 2C8 1.86739 7.94732 1.74021 7.85355 1.64645C7.75979 1.55268 7.63261 1.5 7.5 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V13C1.5 13.3978 1.65804 13.7794 1.93934 14.0607C2.22064 14.342 2.60218 14.5 3 14.5H13C13.3978 14.5 13.7794 14.342 14.0607 14.0607C14.342 13.7794 14.5 13.3978 14.5 13V8.5C14.5 8.36739 14.4473 8.24021 14.3536 8.14645C14.2598 8.05268 14.1326 8 14 8Z"
                      fill="#E86747"
                    />
                    <path
                      d="M14.0001 1.5H10.5001C10.3675 1.5 10.2403 1.55268 10.1465 1.64645C10.0528 1.74021 10.0001 1.86739 10.0001 2C10.0001 2.13261 10.0528 2.25979 10.1465 2.35355C10.2403 2.44732 10.3675 2.5 10.5001 2.5H12.7951L5.14509 10.145C5.05094 10.2392 4.99805 10.3668 4.99805 10.5C4.99805 10.6332 5.05094 10.7608 5.14509 10.855C5.23924 10.9492 5.36694 11.002 5.50009 11.002C5.63324 11.002 5.76094 10.9492 5.85509 10.855L13.5001 3.205V5.5C13.5001 5.63261 13.5528 5.75979 13.6465 5.85355C13.7403 5.94732 13.8675 6 14.0001 6C14.1327 6 14.2599 5.94732 14.3536 5.85355C14.4474 5.75979 14.5001 5.63261 14.5001 5.5V2C14.5001 1.86739 14.4474 1.74021 14.3536 1.64645C14.2599 1.55268 14.1327 1.5 14.0001 1.5Z"
                      fill="#E86747"
                    />
                  </svg>
                </span>{" "}
                See Calendar
              </Button>

              <div>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#344054",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Select your mood
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    id="mood-select"
                    open={moodOpen}
                    onClose={handleMoodClose}
                    onOpen={handleMoodOpen}
                    value={mood}
                    onChange={handleMoodChange}
                    renderValue={(selected) => {
                      const selectedMood = moodlist.find(
                        (item) => item.text === selected
                      );
                      return selectedMood ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={selectedMood.icon}
                            alt={selectedMood.text}
                            style={{ width: "25px", marginRight: "10px" }}
                          />
                          <span>{selectedMood.text}</span>
                        </div>
                      ) : (
                        <em>None</em>
                      );
                    }}
                    sx={{
                      outline: "none",
                      "& .MuiSelect-select": {
                        backgroundColor: "white",
                        border: "2px solid #F6C5B2",
                        borderRadius: "8px",
                        outline: "none",
                        padding: "12px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {moodlist.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.text}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={item.icon}
                          alt={item.text}
                          style={{ width: "25px", marginRight: "10px" }}
                        />
                        <span>{item.text}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#344054",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Select your Goals
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    id="goal-select"
                    open={goalOpen}
                    onClose={handleGoalClose}
                    onOpen={handleGoalOpen}
                    value={goal}
                    onChange={handleGoalChange}
                    sx={{
                      outline: "none",
                      "& .MuiSelect-select": {
                        backgroundColor: "white",
                        border: "2px solid #F6C5B2",
                        borderRadius: "8px",
                        outline: "none",
                        padding: "12px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {goalList.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span>{item}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#344054",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Select your Interests
                </Typography>
                <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
                  <Select
                    id="interest-select"
                    open={interestOpen}
                    onClose={handleInterestClose}
                    onOpen={handleInterestOpen}
                    value={interest}
                    onChange={handleInterestChange}
                    sx={{
                      outline: "none",
                      "& .MuiSelect-select": {
                        backgroundColor: "white",
                        border: "2px solid #F6C5B2",
                        borderRadius: "8px",
                        outline: "none",
                        padding: "12px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {interestList.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span>{item}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                mt={2}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    color: "#191918",
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    boxShadow: "none",
                    textTransform: "none",
                    letterSpacing: "1px",
                    border: "1px solid #E86747",
                    outline: "none",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      border: "1px solid #E86747",
                      scale: "1.05",
                      transform: "scale(1.03)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    color: "white",
                    backgroundColor: "#E86747",
                    borderRadius: "8px",
                    boxShadow: "none",
                    textTransform: "none",
                    letterSpacing: "1px",
                    outline: "none",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#E86747",
                      boxShadow: "none",
                      border: "none",
                      scale: "1.05",
                      transform: "scale(1.03)",
                    },
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "820px",
  maxHeight: "90%",
  bgcolor: "background.paper",
  boxShadow: "0px 4px 100px 0px rgba(255, 255, 255, 0.24)",
  p: 0,
};

const HistoryModal = ({ open, handleClose, historyItems, userId }) => {
  const [items, setHistoryItems] = useState([]);

  useEffect(() => {
    if (historyItems && historyItems.length > 0) {
      setHistoryItems(historyItems);
    }
  }, [historyItems]);

  const deleteItem = async (itemToDelete, indexToDelete) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/history/delete-history`,
        {
          data: {
            content: itemToDelete.text,
            userId: userId,
          },
        }
      );

      if (response.status === 200) {
        console.log("Item deleted:", response.data.message);

        const updatedItems = items.filter(
          (_, index) => index !== indexToDelete
        );
        setHistoryItems(updatedItems);
      }
    } catch (error) {
      console.error(
        "Failed to delete item:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <Modal className={style["products-pop"]} open={open} onClose={handleClose}>
      <Box className={style["box-content"]} sx={{ ...styleModal }}>
        <div className={style["title-box"]}>
          <span>History</span>
          <img src={xIcon} alt="close" onClick={() => handleClose()}></img>
        </div>

        <div
          style={{
            overflowY: "auto",
            maxHeight: "70vh",
          }}
          className={style["list-box"]}
        >
          {items.map((item, index) => (
            <div key={index} className={style["list-item"]}>
              <div className={style["item-left"]}>
                <span className={style["item-text"]}>{item.text}</span>
                <span className={style["item-date"]}>{item.date}</span>
              </div>
              <button
                className={style["item-button"]}
                onClick={() => {
                  deleteItem(item, index);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M6.79663 19C6.79663 20.1 7.69663 21 8.79663 21H16.7966C17.8966 21 18.7966 20.1 18.7966 19V9C18.7966 7.9 17.8966 7 16.7966 7H8.79663C7.69663 7 6.79663 7.9 6.79663 9V19ZM18.7966 4H16.2966L15.5866 3.29C15.4066 3.11 15.1466 3 14.8866 3H10.7066C10.4466 3 10.1866 3.11 10.0066 3.29L9.29663 4H6.79663C6.24663 4 5.79663 4.45 5.79663 5C5.79663 5.55 6.24663 6 6.79663 6H18.7966C19.3466 6 19.7966 5.55 19.7966 5C19.7966 4.45 19.3466 4 18.7966 4Z"
                    fill="#111111"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </Box>
    </Modal>
  );
};
