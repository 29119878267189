import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { robo, sound_icon, sound_off_icon } from "../../../assets/images";
import style from "./listProducts.module.scss";
import { defaultImage } from "../../../assets/images";

const ProductCard = ({
  product,
  myProductsRatings,
  userData,
  setOpenLoginPopUp,
  lastProductRef,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [duration, setDuration] = useState(null);

  const videoRef = useRef(null);

  const handleCardClick = (productId) => {
    if (!userData) {
      setOpenLoginPopUp(true);
    } else {
      navigate(`/product-details/${productId}`);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      if (isHovered) {
        videoRef.current.muted = true;
        videoRef.current.play().catch((error) => {
          console.error("User interaction required to play video", error);
        });
      } else {
        videoRef.current.pause();
      }
    }
  }, [isHovered]);

  return (
    <div key={product._id} className={style.product} ref={lastProductRef}>
      <div className={style["video-player-container"]}>
        {product.pin_video ? (
          <div
            className={`${style["video-player"]} ${
              isHovered ? `${style["active"]}` : ""
            }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <video
              ref={videoRef}
              src={product.pin_video.videoURL}
              // controls={isHovered}
              className={style.video}
              poster={product.pin_video.capture}
              muted={isMuted}
              onLoadedMetadata={handleLoadedMetadata}
            />

            {isHovered && (
              <div
                id="mute"
                style={{
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  zIndex: "1000000",
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => setIsMuted(!isMuted)}
              >
                <img
                  src={isMuted ? sound_off_icon : sound_icon}
                  alt="sound"
                  id="mute-icon"
                  style={{
                    height: "25px",
                    width: "25px",
                    minHeight: "25px",
                    minWidth: "25px",
                    maxHeight: "25px",
                    maxWidth: "25px",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}

            {isHovered && duration && (
              <div
                className={style["video-duration"]}
                style={{
                  position: "absolute",
                  left: "10px",
                  bottom: "10px",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                {Math.floor(duration / 60)}:
                {("0" + Math.floor(duration % 60)).slice(-2)}
              </div>
            )}
          </div>
        ) : (
          <img src={defaultImage} alt="product" />
        )}
      </div>

      <div className={style["product-info"]}>
        <div className={style["product-info-left"]}>
          <div className={style["product-image"]}>
            <div
              className={style["image"]}
              onClick={() => handleCardClick(product._id)}
            >
              {product.image ? (
                <img
                  src={
                    product.image.includes("upload")
                      ? `${process.env.REACT_APP_API_URL}/${product.image}`
                      : product.image
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = robo;
                  }}
                  alt="product"
                />
              ) : (
                <img src={robo} alt="default" />
              )}
            </div>
          </div>

          <div className={style["product-info-rating"]}>
            <span className={style["product-title"]}>{product.name}</span>
            {/* <Rating
              value={
                myProductsRatings.find((productRating) => productRating.product === product._id)?.rating || 0
              }
              readOnly
              precision={0.5}
            /> */}
          </div>
        </div>

        <div className={style["product-details"]}>
          <button
            onClick={() => handleCardClick(product._id)}
            className={style["button-get"]}
          >
            Get
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
