import React from 'react';
import { Modal } from '@mui/material';
import { useContext } from 'react';
import style from './deleteProduct.module.scss';
import axios from 'axios';
import { Context } from '../../state/UserContext';
import { modal_trash, close } from "../../assets/images";
const DeleteProductModal = ({ open, onClose, productId, onDelete }) => {
    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/products/${productId}`);
            onDelete(productId);
            onClose();
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose} className={style.modal}>
            <div className={style['modal-content']}>
                <div className={style['header']}>
                    <img src={modal_trash} />
                    <img src={close} onClick={onClose}/>

                </div>
                <div className={style.title}>
                  Delete Product
                </div>
                <div className={style.subheader}>
                    Are you sure you want to delete this product? This action cannot be undone.
                </div>
                <div className={style.btns}>
                    <button className={style.cancel} onClick={onClose}>Cancel</button>
                    <button className={style.delete} onClick={handleDelete}>Delete</button>
                </div>
              
            </div>
        </Modal>
    );
};

export default DeleteProductModal;
