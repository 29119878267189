import React, { useEffect, useRef, useState } from "react";
import style from "./video-editor.module.scss";

function VideoPlayer({ src, playbackVector, onPlayerChange, onChange }) {
  const videoRef = useRef();
  const [duration, setDuration] = useState();
  const [currentTime, setCurrentTime] = useState(0);

  const checkDuration = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      let duration = videoElement.duration;
      if (!isNaN(duration) && duration !== Infinity) {
        setDuration(duration);
        if (onChange) {
          onChange({
            duration: duration,
            currentTime: videoElement.currentTime,
          });
        }
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    let interval;
    if (videoElement) {
      const onMetadataLoaded = () => {
        if (checkDuration()) {
          clearInterval(interval);
        }
      };
      videoElement.addEventListener("loadedmetadata", onMetadataLoaded);
      interval = setInterval(() => {
        if (checkDuration()) {
          clearInterval(interval);
        }
      }, 500);
      return () => {
        videoElement.removeEventListener("loadedmetadata", onMetadataLoaded);
        clearInterval(interval);
      };
    }
  }, [src]);

  const handleTimeUpdate = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      setCurrentTime(videoElement.currentTime);
      if (onChange) {
        onChange({
          duration: videoElement.duration,
          currentTime: videoElement.currentTime,
        });
      }
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      onPlayerChange(videoElement);
    }
  }, [onPlayerChange]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [src, onChange]);

  return (
    <video ref={videoRef} controls src={src} className={style["video"]}></video>
  );
}

export default VideoPlayer;

// import React, { useEffect, useRef, useState } from 'react';
// import style from "./video-editor.module.scss";

// function VideoPlayer({ src, onPlayerChange, onChange }) {
//   const videoRef = useRef();
//   const [duration, setDuration] = useState();
//   const [currentTime, setCurrentTime] = useState(0);
// useEffect(()=>{
//   const videoElement = videoRef.current;
//   console.log("++++this is the video duration++++",videoElement.duration);
// },[])
//   const handleLoadedMetadata = () => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       let duration = videoElement.duration;
//       console.log("++++this is the video duration++++",videoElement.duration);
//       if (!isNaN(duration) && duration !== Infinity) {
//         setDuration(duration);
//         if (onChange) {
//           onChange({
//             duration: duration,
//             currentTime: videoElement.currentTime,
//           });
//         }
//       } else {
//         console.log("Duration is NaN or Infinity after loadedmetadata event.");
//       }
//     } else {
//       console.log("Video element not found in loadedmetadata handler");
//     }
//   };

//   const handleCanPlay = () => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       let duration = videoElement.duration;
//       if (!isNaN(duration) && duration !== Infinity) {
//         setDuration(duration);
//         if (onChange) {
//           onChange({
//             duration: duration,
//             currentTime: videoElement.currentTime,
//           });
//         }
//       } else {
//         console.log("Duration is NaN or Infinity after canplay event.");
//       }
//     } else {
//       console.log("Video element not found in canplay handler");
//     }
//   };

//   const handleTimeUpdate = () => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       setCurrentTime(videoElement.currentTime);
//       if (onChange) {
//         onChange({
//           duration: videoElement.duration,
//           currentTime: videoElement.currentTime,
//         });
//       }
//     } else {
//       console.log("Video element not found in timeupdate handler");
//     }
//   };

//   useEffect(() => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       onPlayerChange(videoElement);
//     }
//   }, [onPlayerChange]);

//   useEffect(() => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
//       videoElement.addEventListener('canplay', handleCanPlay);
//       videoElement.addEventListener('timeupdate', handleTimeUpdate);
//     } else {
//       console.log("Video element not found during setup effect");
//     }

//     return () => {
//       if (videoElement) {
//         videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
//         videoElement.removeEventListener('canplay', handleCanPlay);
//         videoElement.removeEventListener('timeupdate', handleTimeUpdate);
//       }
//     };
//   }, [src, onChange]);

//   return (
//     <video ref={videoRef} controls src={src} className={style["video"]}>
//     </video>
//   );
// }

// export default VideoPlayer;
// import React, { useEffect, useRef, useState } from 'react';
// import style from "./video-editor.module.scss";

// function VideoPlayer({ src, playbackVector, onPlayerChange, onChange }) {
//   const videoRef = useRef();
//   const [duration, setDuration] = useState();
//   const [currentTime, setCurrentTime] = useState(0);

//   useEffect(() => {
//     const videoElement = videoRef.current;

//     // If playbackVector has the duration, set it directly
//     if (playbackVector && playbackVector.duration) {
//       setDuration(playbackVector.duration);
//       if (onChange) {
//         onChange({
//           duration: playbackVector.duration,
//           currentTime: videoElement.currentTime,
//         });
//       }
//     }
//   }, [playbackVector]);

//   const handleLoadedMetadata = () => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       let duration = videoElement.duration;
//       if (!isNaN(duration) && duration !== Infinity) {
//         setDuration(duration);
//         if (onChange) {
//           onChange({
//             duration: duration,
//             currentTime: videoElement.currentTime,
//           });
//         }
//       } else {
//         console.log("Duration is NaN or Infinity after loadedmetadata event.");
//       }
//     } else {
//       console.log("Video element not found in loadedmetadata handler");
//     }
//   };

//   const handleCanPlay = () => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       let duration = videoElement.duration;
//       if (!isNaN(duration) && duration !== Infinity) {
//         setDuration(duration);
//         if (onChange) {
//           onChange({
//             duration: duration,
//             currentTime: videoElement.currentTime,
//           });
//         }
//       } else {
//         console.log("Duration is NaN or Infinity after canplay event.");
//       }
//     } else {
//       console.log("Video element not found in canplay handler");
//     }
//   };

//   const handleTimeUpdate = () => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       setCurrentTime(videoElement.currentTime);
//       if (onChange) {
//         onChange({
//           duration: videoElement.duration,
//           currentTime: videoElement.currentTime,
//         });
//       }
//     } else {
//       console.log("Video element not found in timeupdate handler");
//     }
//   };

//   useEffect(() => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       onPlayerChange(videoElement);
//     }
//   }, [onPlayerChange]);

//   useEffect(() => {
//     const videoElement = videoRef.current;
//     if (videoElement) {
//       videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
//       videoElement.addEventListener('canplay', handleCanPlay);
//       videoElement.addEventListener('timeupdate', handleTimeUpdate);
//     } else {
//       console.log("Video element not found during setup effect");
//     }

//     return () => {
//       if (videoElement) {
//         videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
//         videoElement.removeEventListener('canplay', handleCanPlay);
//         videoElement.removeEventListener('timeupdate', handleTimeUpdate);
//       }
//     };
//   }, [src, onChange]);

//   return (
//     <video ref={videoRef} controls src={src} className={style["video"]}>
//     </video>
//   );
// }

// export default VideoPlayer;
